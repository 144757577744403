<template>
  <div class="content">
    <PageHeader :title="$t('shortener.header')" subtitle="Links" />
    <div class="page-content container-fluid">
      <div class="container container-add">
        <div class="row">
          <div class="col-12">
            <div class="card card-add-list">
              <div id="close-dialog" class="btn-close" @click="closeDialog"></div>
              <div class="card-body">
                <div class="col-8 d-flex justify-content-center flex-column">
                  <p class="card-text" v-html="$t('shortener.description')"></p>
                  <p>Encurte seus links, acompanhe métricas personalizadas e potencialize suas campanhas com resultados gerenciáveis.</p>
                  <div class="w-100"></div>
                  <div>
                    <button @click="showModalInfo()" class="btn btn-yup btn-add to-left">
                      {{ $t("shortener.create") }}
                    </button>
                  </div>
                </div>
                <div class="col d-flex justify-content-center align-items-center">
                  <div class="star-1-flow" style="background-image: url('assets/img/star_flow_1.png')">
                    <div class="star-2-flow" style="background-image: url('assets/img/star_flow_2.png')">
                      <img v-b-toggle.sidebar-right-flow src="@/assets/blue-success-feedback.gif" alt="Add"
                        width="167" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content container-fluid pb-0">
        <div class="row">
          <div
            class="users-column"
            :class="{
              'col-lg-12': $store.state.plans.omniPlan,
              'col-lg-12': !$store.state.plans.omniPlan,
            }"
          >
            <links-card ref="linksCard" />
          </div>
        </div>
    </div>
  </div>
</div>
      <!-- Title -->
</template>

<script>
import PageHeader from '@/components/rebranding/PageHeader.vue';
import LinksCard from '@/components/cards/LinksCard.vue';

export default {
  name: 'Users',
  components: {
    PageHeader,
    LinksCard,
  },
  data() {
    return {
      users: [],
      fetched: false,
      form: {
        q: '',
        page: 1,
      },
      pages: 1,
    };
  },
  computed: {
    agentStatus() {
      const agents = {};

      if (this.$live.managedAgents?.length) {
        this.$live.managedAgents.forEach((agent) => {
          if (agent.email) agents[agent.email] = agent.status;
        });
      }

      return agents;
    },
  },
  methods: {
    updateUserCards() {
      this.$refs?.usersCard?.fetch(1);
    },
    showModalInfo() {
      this.$refs.linksCard.showModalCreate();
    },
    closeDialog() {
      document.querySelectorAll('.container-add').forEach((el) => el.remove());
    },
  },
};
</script>

<style scoped lang="scss">
.main-search {
  margin: 0 0 2rem 0;

  .text-field {
    position: relative;
    margin: 0 auto;
    max-width: 952px;
    background: #fff;
    border: thin solid #ccc;
    border-radius: 0.2rem;
    width: 100%;

    input {
      background: transparent;
      padding-left: 2rem;
      padding-right: 2rem;
      width: 100%;
      height: 48px;
      border: none;
    }

    .material-icons-outlined {
      position: absolute;
      right: 8px;
      top: 8px;
      font-size: 2rem;
      pointer-events: none;
    }
  }
}

.flex-c {
  flex-direction: column;
}

.align-r {
  align-self: flex-end;
}

.trigger-type-button {
  display: flex;
  width: 100%;
  padding: 0.625rem;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 0.5rem;
  border: 1px dashed #e5e5e5;
}

.trigger-type-button-channel {
  border: 1px solid #e5e5e5;
}

.trigger-type-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.trigger-type-description .trigger-type-titles h3 {
  color: #595959;
  font-size: 0.875rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.trigger-type-description-channel .trigger-type-titles h3 {
  color: #595959;
  font-size: 0.875rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.trigger-type-titles-item {
  font-size: 0.875rem !important;
  margin-top: 10px;
  margin-left: 10px;
}

.trigger-type-description-channel .trigger-type-titles p {
  color: #aaa;
  font-size: 0.75rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
}

.flow-card-title-info {
  color: var(--gray-font-color-3);
  margin-left: 5px;
}

.trigger-type-description .trigger-type-titles p {
  color: #aaa;
  font-size: 0.75rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
}

.trigger-type-description-channel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 75%;
}

.trigger-type-description-channel-item {
  width: 90%;
}

.trigger-type-checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60px;
}

.trigger-type-icon {
  display: flex;
  width: 4rem;
  align-items: flex-start;
  gap: 0.625rem;
}

.trigger-type-icon-channel {
  display: flex;
  width: 3rem;
  align-items: flex-start;
  margin-left: 5px;
}

.trigger-type-icon-channel img {
  width: 60%;
  margin-top: 10px;
  width: 2.14006rem;
  height: 2.14006rem;
}

.form-wrapper {
  flex: 1 0 20%;

  .form-group {
    height: 55px;
  }

  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;

    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}

@media (min-width: 1660px) {
  .form-wrapper {
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
  }
}

@media (max-width: 767.98px) {
  .form-wrapper {
    flex: 1 0 40%;
  }
}

.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }

  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }

  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }

  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}

#close-dialog {
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
  transform: scale(1.25);
  padding: 10px;
  background: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

#close-dialog::after {
  content: "\e5cd";
  font-family: "Material Symbols Outlined";
  color: #6b23dc;
  transform: scale(1.6);
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.custom-control-label {

  &::after,
  &::before {
    top: -0.5rem;
  }
}

.btn i {
  line-height: 1;
  color: inherit;
}

.btn {
  margin-bottom: 5px;
}

.btn-search {
  position: absolute;
  right: 0;
  top: 0;
}

.type-flow-item {
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  display: block;
}

.type-flow-item:hover {
  background: #752de6;
  color: #fff;
}

.type-flow-item:hover p {
  color: #fff;
}

.type-flow-item:hover i {
  color: #fff;
}

.type-flow-item-active {
  background: #752de6;
  color: #fff;
}

.type-flow-item-active p {
  color: #fff;
}

.type-flow-item-active i {
  color: #fff;
}

.flow-trigger-icon {
  font-size: 25px;
  cursor: pointer;
}

.color-white {
  color: #fff !important;
}

.m-left-5 {
  margin-left: 5px !important;
}

.flow-channel-item {
  border: solid 1px #ecedee;
  box-shadow: 0px 0px 12px #752de629;
  width: 57px;
  height: 57px;
  border-radius: 4px;
  padding: 10px;
  float: left;
}

.flow-channel-item-line {
  width: 80px;
  height: 0px;
  border: 1px solid #9ca7b0;
  float: left;
  margin-top: 25px;
  margin-left: 5px;
  margin-right: 5px;
}

.element-flow-item-card {
  margin: 0 auto;
  width: 209px;
  margin-bottom: 45px;
  margin-top: 60px;
}

.element-flow-item-title {
  color: #0e1421;
  font-weight: 600;
  margin-left: 10px;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  height: 1.2em;
  white-space: nowrap;
}

.refresh-date-item {
  color: #9ca7b0;
  font-size: 10px;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.flow-channel-item-check {
  float: left;
}

.flow-channel-item-menu {
  float: right;
}

.flow-channel-item-menu img {
  height: 25px;
}

.flow-channel-item-menu {
  cursor: pointer !important;
}

.btn-new-flow {
  background: #752de6 !important;
  border: solid 1px #752de6 !important;
}

.btn-new-flow:hover {
  background: #8238f9 !important;
}

.tab-flow ul li.active {
  color: #8238f9 !important;
}

.tab-flow {
  padding: 30px 30px 0px 0px;
  border-bottom: solid 1px #0000001a;
  width: 100%;
  margin-bottom: 20px;
}

.tab-flow ul {
  list-style: none;
  margin: 0px;
  padding-left: 15px;
  position: relative;
}

.tab-flow ul li {
  color: #423c4c;
  padding-bottom: 50px;
  float: left;
}

.tab-flow ul li {
  width: 180px;
  padding-left: 30px;
  cursor: pointer;
}

.tab-flow ul .tab-indicator {
  width: 180px;
  content: "";
  border-bottom: 2px solid #752de6;
  display: inline-block;
  position: absolute;
  bottom: -80px;
  left: 10px;
  transition: left 0.5s;
}

.tab-flow ul li span {
  color: #423c4c;
  float: left;
  font-size: 28px !important;
}

.tab-flow ul li strong {
  color: #423c4c;
  float: left;
  font-size: 18px;
  font-weight: 200;
}

.tab-flow ul li:hover strong {
  color: #8238f9 !important;
}

.tab-flow ul li:hover span {
  color: #8238f9 !important;
}

.tab-flow ul li.active strong {
  color: #8238f9 !important;
}

.tab-flow ul li.active span {
  color: #8238f9 !important;
}

.title-new-flow {
  font-weight: 100;
}

.flow-channel-item-modal {
  border: solid 1px #ecedee;
  box-shadow: 0px 0px 12px #752de629;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}

.flow-channel-item-modal img {
  width: 30% !important;
}

.top2 {
  margin-top: 2px !important;
}

.left15 {
  margin-left: 15px !important;
}

@media (max-width: 666px) {
  .tab-indicator {
    display: none !important;
  }
}

.no-item-flow span {
  font-size: 70px !important;
  color: #ccc;
}

.no-item-flow h3 {
  color: #ccc;
}

.no-item-flow {
  margin-top: 20px;
  margin-bottom: 20px;
}

.all-check-box {
  .check-all-info {
    color: var(--foreground);
  }
}

.container-add,
.container-catalogs {
  max-width: 1740px;
}

.card-add-list {
  background: #edf6ff;
  overflow: hidden;
  position: relative;
  height: 264px;
  border-radius: 8px;
  margin-bottom: 4rem;
  box-shadow: 0px 3px 36px 6px #d4e3ff59 !important;

  >.card-body {
    display: flex;
  }

  .card-text {
    color: #0d0519;
    font-size: 24px;
    position: relative;
    z-index: 1;
  }

  .img-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 437px;
    height: 437px;
    background-color: #a941fa;
    border-radius: 50%;
    z-index: 0;
  }

  .star-1-flow {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 437px;
    height: 120%;
    border-radius: 0;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .star-2-flow {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 300px;
    width: 300px;
    text-align: center;
    padding-top: 50px;
  }

  .btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    background-color: #6b23dc;
    color: #fff;
    font-weight: 400;
    border-radius: 8px;
    font-size: 1rem;
  }

  .btn-add-template {
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    background-color: none;
    color: #6b23dc;
    font-weight: 400;
    border-radius: 8px;
    font-size: 1rem;
    margin-left: 10px;
    border: solid #6b23dc 1px;
  }
}

.title-list {
  color: var(--gray-font-color-5);
  font-weight: 400;
  display: block;
  padding: 8px 20px;
}

.title-list-icon {
  font-weight: 200;
  font-size: 24px;
  padding-top: 10px;
  float: left;
}

.title-list-text {
  font-weight: 200;
  margin-top: 10px;
  display: block;
  float: left;
}

.options-cat {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  gap: 10px;

  li {
    color: var(--gray-font-color-5);
  }
}

.btn.btn-add-list {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  border: unset !important;
  font-size: 1rem;

  &:hover,
  &:focus {
    color: var(--clr-yup-purple-hover) !important;

    span {
      border-color: var(--clr-yup-purple-hover);
    }
  }

  span {
    margin-left: 5px;
    border: 1px solid var(--clr-yup-purple);
    border-radius: 50%;
    font-size: 18px;
    font-weight: 400;
  }
}

hr.purple {
  width: 98%;
  border-top: 5px solid #6b23dc3d;
  border-radius: 75px;
}

.btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  background-color: #6b23dc;
  color: #fff;
  font-weight: 400;
  border-radius: 8px;
  font-size: 1rem;
}

.card-flow-template {
  background: #fff;
  border: solid 1px #dfdfdf;
  border-radius: 10px;
  margin-bottom: 10px;
}

.title-list-line {
  height: 5px;
  background: #6b23dc;
  width: 130px;
  position: absolute;
  bottom: 17px;
  border-radius: 10px;
  right: 0;
  left: 1%;
  transition: left 0.3s;
}

.c-pointer {
  cursor: pointer !important;
}

.title-list:hover {
  color: #6b23dc;
}

.trigger-flow-active-icon {
  position: absolute;
  right: -10px;
  top: -10px;
}

.trigger-flow-active {
  border: solid 1px #6b23dc !important;
}

.trigger-flow-active h3 {
  color: #6b23dc !important;
}

.trigger-flow-active p {
  color: #6b23dc !important;
}

::v-deep {
  .dropdown .dropdown-menu.dropdown-menu-right {
    right: auto !important;
    left: 0 !important;
  }

  .dropdown-item {
    display: flex !important;
    align-items: center !important;
    gap: 10px;
    color: var(--gray-font-color-5);
  }

  .pagination {
    justify-content: end;
  }
}

::v-deep #sidebar-right-flow {
  background-color: var(--background-2) !important;
  width: 525px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

.add-prod-form {
  .input-group {
    margin-bottom: 2rem;
  }

  label {
    display: flex;
    gap: 5px;
    width: 100%;
    margin-bottom: 0.725rem;
    font-size: 1rem !important;

    span {
      color: var(--gray-font-color-3);
    }
  }
}

.border-radius-5 {
  border-radius: 0.5rem !important;
}

.trigger-type {
  padding: 0.625rem;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 0.25rem;
  background: #f2f2f2;
}

.trigger-type h3 {
  color: #0d0519;
  text-align: center;
  /* H2/Poppins 16px w500 */
  font-size: 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  float: left;
  margin: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-top: 3px;
  margin-right: 10px;
}

.info-channel {
  color: #0d0519;
  text-align: center;
  font-size: 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
}

.trigger-type span {
  color: #aaaaaa;
}

@media screen and (max-width: 1000px) {
  .card-add-list .card-text {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 860px) {
  .card-add-list .card-text {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 800px) {
  .card-add-list {
    display: none !important;
  }
}

.span-type-flow {
  position: absolute;
  top: 25%;
  font-size: 13px;
  background: #e8e8e8;
  padding: 5px;
  padding-right: 5px;
  padding-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
}

.form-wrapper {
  flex: 1 0 20%;

  .form-group {
    height: 55px;
  }

  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;

    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}

.darkmode .rebranding .form-group .form-control,
.rebranding .form-group .form-control {
  border-right: unset !important;
}

.form-group .icon_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--form-border);
  border-left-color: transparent;
  border-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}

.icon-no-item {
  display: block;
  font-size: 60px;
  color: #8f8f8f;
  margin-bottom: 10px;
}
</style>
