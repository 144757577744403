<template>
  <div>
    <div v-if="loadingClickInsights" class="highlights">
      <div class="qf-loading d-flex align-items-center justify-content-center" v-if="loadingEvents">
        <LoadingAnim />
      </div>
    </div>
    <div v-else class="highlights">
      <div class="row">
        <div class="col-sm-3">
          <div class="card">
            <CreatedAt />
            <h3>{{ new Date(clickInsights[0].created_at).toLocaleDateString('pt-BR') }}</h3>
            <p>Criado em</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card">
            <TotalClicks />
            <h3>{{clickInsights[0].total_clicks | humanSize}}</h3>
            <p>Cliques totais</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card">
            <HumanClicks />
            <h3>{{clickInsights[0].human_clicks | humanSize}}</h3>
            <p>Cliques humanos</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card">
            <ClicksChange />
            <h3>{{clickInsights[0].human_clicks_change === "999" ? 'N/A' : clickInsights[0].human_clicks_change + '%'}}</h3>
            <p>Variação de cliques</p>
          </div>
        </div>
      </div>
      <div :class="{ 'd-none': currentChart !== '' }">
        <chart
          format="pie"
          ref="chart"
          title="Cliques por redes sociais"
          :data="dataMessagesEvents"
          :loading="loadingEvents" />
      </div>
    </div>
    <div class="highlights" :class="{ 'd-none': currentChart !== 'providers' }">
      <div class="pr-1">
        <chart
          format="pie"
          title="Cliques por cidade"
          :loading="loadingProviders"
          :data="dataMessagesProviders" />
      </div>
    </div>
    <div class="highlights" :class="{ 'd-none': currentChart !== 'devices' }">
      <div class="pr-1">
        <chart
          format="pie"
          title="cliques por sistema operacional"
          :loading="loadingDevices"
          :data="dataMessagesDevices" />
      </div>
    </div>
    <div class="highlights" :class="{ 'd-none': currentChart !== 'events' }">
      <div class="pr-1">
        <chart
          format="pie"
          ref="chart"
          title="Cliques por redes sociais"
          :data="dataMessagesEvents"
          :loading="loadingEvents" />
      </div>
    </div>
    <div class="highlights" :class="{ 'd-none': currentChart !== 'browsers' }">
      <div class="pr-1">
        <chart
          format="pie"
          title="Cliques por País"
          :loading="loadingBrowsers"
          :data="dataMessagesBrowsers" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HumanClicks from '@/components/rebranding/animLottie/HumanClicks.vue';
import TotalClicks from '@/components/rebranding/animLottie/TotalClicks.vue';
import ClicksChange from '@/components/rebranding/animLottie/ClicksChange.vue';
import CreatedAt from '@/components/rebranding/animLottie/CreatedAt.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

import Chart from '@/components/rebranding/Chart.vue';
import axios from '@/plugins/axios';
import moment from 'moment';

export default {
  name: 'MailReportsStats',
  components: {
    ClicksChange,
    CreatedAt,
    // MsgGreen,
    TotalClicks,
    HumanClicks,
    Chart,
    LoadingAnim,
    // EmailReport,
  },
  props: ['dateGte', 'dateLte'],
  computed: {
    timeWindow() {
      if (moment(this.endDate).diff(moment(this.startDate), 'days')) {
        return 'daily';
      }
      return 'hourly';
    },
    events() {
      console.log('dataMessagesEvents', this.dataMessagesEvents);

      return 0;
    },
    providers() {
      console.log('dataMessagesProviders', this.dataMessagesProviders);

      return 0;
    },
    devices() {
      console.log('dataMessagesDevices', this.dataMessagesDevices);

      return 0;
    },
    browsers() {
      console.log('dataMessagesBrowsers', this.dataMessagesBrowsers);

      return 0;
    },
  },
  watch: {
    dateGte(newVal, oldVal) {
      console.log('Prop changed [dateGte]: ', newVal, ' | was: ', oldVal);
      this.startDate = newVal.replace(/\s/, 'T');
    },
    dateLte(newVal, oldVal) {
      console.log('Prop changed [dateLte]: ', newVal, ' | was: ', oldVal);
      this.endDate = newVal.replace(/\s/, 'T');
    },
  },
  data() {
    const date = new Date().toISOString().substring(0, 10);

    return {
      linkId: '',
      timeout: 0,
      sentMessagesTotal: 0,
      sentMessagesTarifeds: 0,
      sentMessagesFaileds: 0,

      loadingSentMessages: false,
      dataSentMessages: [],

      currentChart: '',

      loadingEvents: false,
      dataMessagesEvents: [],

      loadingProviders: false,
      dataMessagesProviders: [],

      loadingDevices: false,
      dataMessagesDevices: [],

      loadingBrowsers: false,
      dataMessagesBrowsers: [],

      clickInsights: [],
      loadingClickInsights: true,

      startDate: this.dateGte.replace(/\s/, 'T') || `${date}T00:00:01`,
      endDate: this.dateLte.replace(/\s/, 'T') || `${date}T23:59:59`,
    };
  },
  mounted() {
    console.log('Prop changed [mounted]:', this.startDate, this.endDate);
    this.linkId = this.$route.params.id;
    this.fetch();
  },
  filters: {
    humanSize: (value) => {
      if (!value) return 0;

      if (value < 1000) return value;

      let base = 1;
      let sulfix = '';

      if (value >= 1000000000) {
        base = 1000000000;
        sulfix = 'Bilhão(ões)';
      } else if (value >= 1000000) {
        base = 1000000;
        sulfix = 'Milhão(ões)';
      } else if (value >= 1000) {
        base = 1000;
        sulfix = 'Mil';
      }

      // return `${[value, base]} Mil`;
      return `${(value / base).toFixed()} ${sulfix}`;
    },
  },
  methods: {
    choose(chart) {
      this.currentChart = chart;
      console.log('currentChart', chart);
    },
    fetch(callback) {
      this.loadingSentMessages = true;
      this.loadingEvents = true;
      this.loadingProviders = true;
      this.loadingDevices = true;
      this.loadingBrowsers = true;

      clearTimeout(this.timeout);

      // Prevent multiple requests
      this.timeout = setTimeout(() => {
        this.fetchAll().then(() => {
          if (callback) callback();
        });
      }, 200);
    },
    async fetchAll() {
      // await this.fetchSentMessages();
      await this.fetchEvents();
      console.log('localTick::fetchAll');
      return true;
    },
    async fetchSentMessages() {
      const tarifeds = await this.fethMessageInsights('status', [
        'blocked',
        'bounced',
        'sent',
        'dropped',
        'accepted',
        'delivered',
        'opened',
      ]);

      const faileds = await this.fethMessageInsights('status', [
        'failed',
      ]);

      this.dataSentMessages = [
        {
          values: tarifeds,
          group_by: 'Tarifadas',
        },
        {
          values: faileds,
          group_by: 'Falhas',
        },
      ];

      console.log({ tarifeds, faileds });

      this.loadingSentMessages = false;

      const tarifedsC = tarifeds.reduce((c, item) => c + item[1], 0);
      const failedsC = faileds.reduce((c, item) => c + item[1], 0);

      this.sentMessagesTotal = tarifedsC + failedsC;
      this.sentMessagesTarifeds = tarifedsC;
      this.sentMessagesFaileds = failedsC;
    },
    async fetchEvents() {
      this.clickInsights = await this.fetchClickInsights();
      this.loadingClickInsights = false;

      this.dataMessagesEvents = await this.fethEventInsights('social', null, null);
      this.loadingEvents = false;

      this.dataMessagesProviders = await this.fethEventInsights('city', null, null);
      this.loadingProviders = false;

      this.dataMessagesDevices = await this.fethEventInsights('os', null, null);
      this.loadingDevices = false;

      this.dataMessagesBrowsers = await this.fethEventInsights('country', null, null);
      this.loadingBrowsers = false;

      this.$emit('data-loaded', this.dataMessageProviders);
    },
    fethMessageInsights(field, values) {
      let filters = [];

      if (field) filters = [{ field, values }];

      const params = {
        begin_date: this.startDate,
        direction: 'outbound',
        end_date: this.endDate,
        time_window: this.timeWindow,
        filters,
      };

      return axios().post('/email/insights', params).then((resp) => resp.data.map((item) => [
        item.date_time,
        item.values.reduce((accumulator, value) => accumulator + value, 0),
      ]));
    },
    fethEventInsights(groupBy, field, values) {
      let filters = [];

      if (field) filters = [{ field, values }];

      const params = {
        begin_date: this.startDate,
        direction: 'outbound',
        end_date: this.endDate,
        group_by: groupBy,
        time_window: this.timeWindow,
        filters,
      };

      return axios().get(`/shortener/report/${this.linkId}`, { params }).then((resp) => {
        const reorder = {};

        resp.data.forEach((item) => {
          const date = item.date_time;

          Object.entries(item.values).forEach(([group, value]) => {
            if (group === '') {
              group = 'Desconhecida';
            }

            if (!reorder[group]) reorder[group] = [];

            reorder[group].push([date, value]);
          });
        });

        return Object.keys(reorder).map((group) => ({ group_by: group, values: reorder[group] }));
      });
    },
    fetchClickInsights() {
      const params = {
        begin_date: this.startDate,
        direction: 'outbound',
        end_date: this.endDate,
        group_by: 'default',
        time_window: this.timeWindow,
      };

      return axios().get(`/shortener/report/${this.linkId}`, { params }).then((resp) => resp.data);
    },

    channel() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        time_window: this.timeWindow,
        group_by: 'channel',
      };

      axios().post('webchat/statistics/data', params).then((resp) => {
        console.log('webchat/statistics/data', resp.data);
        // parse data
        this.dataChannels = resp.data.map((item) => {
          item.values = item.values.map((value) => [value.date, value.count]);
          return item;
        });

        // console.log('chart::2', this.date);

        // this.series_line = Object.values(series);
        this.loadingChannels = false;
      });
    },
    finished() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        time_window: this.timeWindow,
        group_by: 'channel',
      };

      axios().post('webchat/statistics/finished', params).then((resp) => {
        // this.data = resp;
        // this.series_line = Object.values(series);
        console.log('webchat/statistics/finished', resp.data);
      });
    },
    exportData(format) {
      this.$refs.chart.exportData(format);
    },
  },
};
</script>

<style lang="scss" scoped>
.highlights {
  .card {
    padding: .92rem;

    p {
      text-align: center;
      color: slategray;
    }

    h3 {
      margin: .62rem 0;
      font-size: 1.3rem;
      font-weight: 600;
      text-align: center;
    }

    &::v-deep {
      .svg_wrapper {
        z-index: 1 !important;
        margin: 0 auto !important;
        top: 0 !important;
        width: 120px !important;
        height: 120px !important;
      }
    }
  }
}
</style>
