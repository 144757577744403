<template>
  <div class="main row m-0">
    <div class="col-md-6">
      <header class="d-flex justify-content-between">
        <img v-if="whitelabelReady" class="logo" alt="Omni Business" :src="whitelabelLogo" />
        <div class="language">
          <OptLangRadio />
        </div>
      </header>
      <div class="row">
        <template v-if="routing">
          <div class="col-md-6">
            <router-view />
          </div>
        </template>
        <div class="col-12 sub-intro" v-else>
          <h1>
            {{ $t('auth.welcome') }}
          </h1>
          <h2>
            {{ $t('auth.subtitle') }}
          </h2>
          <div class="row">
            <div class="col-12 col-md-4 mb-4">
              <ButtonComponent @click="routing = true" is-block>Login</ButtonComponent>
            </div>
            <div class="col-12 col-md-4">
              <ButtonComponent variant="secondary" @click="launchRegister()" is-block>{{ $t('app.register') }}
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 d-none d-md-block">
      <aside class="samples">
        <div class="samples-bg">
          <svg viewBox="40 40 750 910" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M422.417 596.565C320.154 597.873 236.194 516.033 234.886 413.771C233.578 311.508 315.418 227.548 417.68 226.24L602.843 223.872L605.211 409.034C606.519 511.297 524.679 595.257 422.417 596.565Z"
              fill="#752DE6" />
            <circle cx="507" cy="131" r="85" stroke="#0D0519" stroke-width="4" />
            <path d="M241 131C241 84.0558 279.056 46 326 46H506V216H326C279.056 216 241 177.944 241 131Z"
              stroke="#0D0519" stroke-width="4" />
            <path d="M323 131C323 84.0558 361.056 46 408 46H506V216H408C361.056 216 323 177.944 323 131Z"
              stroke="#0D0519" stroke-width="4" />
            <line x1="507" y1="134" x2="239" y2="134" stroke="#0D0519" stroke-width="4" />
            <mask id="path-6-inside-1_2350_27908" fill="white">
              <path
                d="M755.421 196.995C746.728 205.48 736.45 212.171 725.172 216.684C713.895 221.197 701.838 223.445 689.692 223.299C677.546 223.152 665.547 220.615 654.381 215.832C643.215 211.049 633.101 204.113 624.615 195.421C616.13 186.729 609.44 176.45 604.926 165.173C600.413 153.895 598.165 141.839 598.312 129.692C598.458 117.546 600.995 105.547 605.778 94.3814C610.561 83.2155 617.497 73.1011 626.189 64.6157L690.805 130.805L755.421 196.995Z" />
            </mask>
            <path
              d="M755.421 196.995C746.728 205.48 736.45 212.171 725.172 216.684C713.895 221.197 701.838 223.445 689.692 223.299C677.546 223.152 665.547 220.615 654.381 215.832C643.215 211.049 633.101 204.113 624.615 195.421C616.13 186.729 609.44 176.45 604.926 165.173C600.413 153.895 598.165 141.839 598.312 129.692C598.458 117.546 600.995 105.547 605.778 94.3814C610.561 83.2155 617.497 73.1011 626.189 64.6157L690.805 130.805L755.421 196.995Z"
              fill="#E6662D" stroke="white" stroke-width="8" mask="url(#path-6-inside-1_2350_27908)" />
            <mask id="path-7-inside-2_2350_27908" fill="white">
              <path
                d="M755.421 196.995C772.975 179.858 783.003 156.449 783.298 131.918C783.593 107.388 774.132 83.7442 756.995 66.1896C739.857 48.635 716.449 38.6072 691.918 38.312C667.387 38.0168 643.744 47.4785 626.189 64.6157L690.805 130.805L755.421 196.995Z" />
            </mask>
            <path
              d="M755.421 196.995C772.975 179.858 783.003 156.449 783.298 131.918C783.593 107.388 774.132 83.7442 756.995 66.1896C739.857 48.635 716.449 38.6072 691.918 38.312C667.387 38.0168 643.744 47.4785 626.189 64.6157L690.805 130.805L755.421 196.995Z"
              fill="#E6662D" stroke="white" stroke-width="8" mask="url(#path-7-inside-2_2350_27908)" />
            <path
              d="M792.5 599C742.772 599 695.081 579.246 659.917 544.083C624.754 508.919 605 461.228 605 411.5C605 361.772 624.754 314.081 659.917 278.917C695.081 243.754 742.772 224 792.5 224V411.5L792.5 599Z"
              fill="#ADE62D" />
            <path
              d="M608 587.5C608 637.228 588.246 684.919 553.083 720.083C517.919 755.246 470.228 775 420.5 775C370.772 775 323.081 755.246 287.917 720.083C252.754 684.919 233 637.228 233 587.5L333.226 587.5C333.226 610.647 342.421 632.845 358.788 649.212C375.155 665.579 397.353 674.774 420.5 674.774C443.647 674.774 465.845 665.579 482.212 649.212C498.579 632.845 507.774 610.647 507.774 587.5L608 587.5Z"
              fill="#A14720" />
            <circle cx="145" cy="313" r="88" fill="#14142B" />
            <circle cx="145" cy="860" r="46" stroke="#0D0519" stroke-width="4" />
            <circle cx="145" cy="860" r="46" stroke="#0D0519" stroke-width="4" />
            <circle cx="145" cy="860" r="86" stroke="#0D0519" stroke-width="4" />
            <circle cx="145" cy="860" r="86" stroke="#0D0519" stroke-width="4" />
            <circle cx="509" cy="863" r="88" fill="#14142B" />
            <path d="M237 863C237 814.399 276.399 775 325 775H509V951H325C276.399 951 237 911.601 237 863Z"
              fill="#0D0519" />
            <path
              d="M509 775C485.661 775 463.278 784.271 446.775 800.775C430.271 817.278 421 839.661 421 863C421 886.339 430.271 908.722 446.775 925.225C463.278 941.729 485.661 951 509 951L509 863V775Z"
              fill="white" />
            <circle cx="693" cy="676" r="86" transform="rotate(-90 693 676)" fill="white" stroke="black"
              stroke-width="4" />
            <path d="M693 946C645.504 946 607 907.496 607 860L607 678L779 678L779 860C779 907.496 740.496 946 693 946Z"
              fill="white" stroke="black" stroke-width="4" />
            <path
              d="M605 676C605 699.339 614.271 721.722 630.775 738.225C647.278 754.729 669.661 764 693 764C716.339 764 738.722 754.729 755.225 738.225C771.729 721.722 781 699.339 781 676L693 676L605 676Z"
              fill="#0D0519" />
            <path d="M221.336 407.097L227.637 585.57L42.9671 398.34L221.336 407.097Z" fill="#ADE62D" />
            <path d="M229.336 591.097L235.637 769.57L50.9671 582.34L229.336 591.097Z" fill="#E6662D" />
          </svg>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { load } from 'recaptcha-v3';
import DarkMode from '@/assets/mixins/detectDark';
import OptLangRadio from '@/components/OptLangRadio.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import Logo from '@/assets/logo-v2.svg';

export default {
  name: 'AuthIntro',
  components: {
    OptLangRadio,
    ButtonComponent,
  },
  mixins: [DarkMode],
  watch: {
    $route(to, from) {
      // if (from.path === '/2fa') {
      //   this.routing = true;
      // } else if (to.path === '/login') {
      //   this.routing = false;
      // }

      if (to.path === '/login') {
        this.routing = false;
      }
    },
  },
  data() {
    return {
      routing: this.$router.currentRoute.path !== '/login',
      logo: null,
    };
  },
  computed: {
    whitelabelReady() {
      return !!this.$store?.state?.admin?.whitelabel?.id;
    },
    whitelabelLogo() {
      return this.$store?.state?.admin?.whitelabel?.logo ?? Logo;
    },
    whitelabel() {
      return this.$store?.state?.admin?.whitelabel ?? null;
    },
  },
  mounted() {
    const hostname = window.location.hostname;

    switch (hostname) {
      case 'smsycorporativo.algartelecom.com.br':
        this.companyName = 'Algar Telecom';
        break;
      default:
        console.info('Default favicon');
    }
  },
  methods: {
    async launchRegister() {
      await this.$router.push('/register');
      this.routing = true;
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  padding: 39px 23px;

  @media (min-width: 768px) {
    padding: 92px 88px;
  }

  header {
    margin-bottom: 66px;
  }

  .logo {
    width: 126px;
    height: 30px;
  }

  .sub-intro {
    margin-top: 10vh;

    h1 {
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 50px;
        text-align: left;
      }
    }

    h2 {
      color: #747374;
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 32px;
        text-align: left;
      }
    }
  }
}
</style>
