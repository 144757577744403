<template>
  <CardComponent variant="tertiary">
    <template #header>
      {{ $tc('app.report', 2) }}
      <ul class="actions top-right">
        <li>
          <a href="javascript:void(0)" @click="fetch(1)"><i class="icon dripicons-clockwise"></i></a>
        </li>
      </ul>
    </template>
    <TableComponent :busy="loading" :fields="fields" :items="analytics"></TableComponent>
  </CardComponent>
</template>

<script>
import moment from 'moment';
import Pagination from '@/components/Pagination.vue';
import CardComponent from '@/components/CardComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';
import PaginationComponent from '@/components/PaginationComponent.vue';

export default {
  name: 'SmsMonitorAnalytics',
  components: {
    CardComponent,
    TableComponent,
  },
  data() {
    return {
      loading: false,
      form: {
        page: 1,
        filter: {
          created_at: {
            gte: moment().format('YYYY-MM-DD'),
            lte: moment().format('YYYY-MM-DD 23:59:59'),
          },
        },
      },
      fields: [
        {
          key: 'provider',
        },
        {
          key: 'carrier',
        },
        {
          key: 'total_tests',
        },
        {
          key: 'deliverability',
          formatter: (value) => `${value}%`,
        },
        {
          key: 'dlr_delay_median',
          formatter: (value) => this.formatTime(value),
        },
        {
          key: 'receipt_delay_median',
          formatter: (value) => this.formatTime(value),
        },
      ],
      analytics: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.loading = true;
      this.$store.dispatch('fetchMonitorAnalytics', this.form).then((response) => {
        this.analytics = response;
        this.pages = response.last_page;
      }).finally(() => {
        this.loading = false;
      });
    },
    formatTime(seconds) {
      const duration = moment.duration(seconds, 'seconds');

      const hours = duration.hours();
      const minutes = duration.minutes();
      const remainingSeconds = duration.seconds();

      const parts = [];
      if (hours > 0) parts.push(`${hours}h`);
      if (minutes > 0) parts.push(`${minutes}m`);
      if (remainingSeconds > 0 || parts.length === 0) parts.push(`${remainingSeconds}s`);

      return parts.join(' ');
    },
  },
};
</script>
