<template>
  <Page :locked="locked" :title="'Assine Plano de RCS'">
    <PageHeader :title="'RCS Google'" :subtitle="$tc('app.agent', 2)" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col">
          <card ref="card" :actions="true" block :title="'Seus Agentes RCS'" :loading="loading" @reload="fetchAgents">
            <template #body>
              <TableComponent :fields="fields" :items="agents" :busy="loading">
                <template #cell(status)="slotProps">
                  <BadgeComponent variant="success" v-if="slotProps.data == 'active'">Ativo</BadgeComponent>
                  <BadgeComponent variant="warning" v-if="slotProps.data == 'pending'">Pendente</BadgeComponent>
                  <BadgeComponent variant="warning" v-if="slotProps.data == 'waiting'">Pendente</BadgeComponent>
                </template>
              </TableComponent>
            </template>
          </card>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import Page from '@/components/Page.vue';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Card from '@/components/rebranding/Card.vue';
import Tab from '@/directives/Tab';
import TableComponent from '@/components/TableComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';

export default {
  name: 'RcsAgentList',
  components: {
    Page,
    PageHeader,
    Card,
    TableComponent,
    BadgeComponent,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      fields: [
        'name',
        'id',
        'status',
      ],
      loading: true,
      agents: [],
      filter: {
        carrier: [],
        status: [],
        from: '',
        to: '',
        segments: '',
        campaign_id: '',
      },
      q: '',
      pages: 1,
      campaigns: [],
    };
  },
  computed: {
    locked() {
      if (this.$store.state.account.plans.rcs == null) {
        return true;
      }
      this.fetchAgents();
      return false;
      // return this.submited;
    },
  },
  methods: {
    fetchAgents(page = 1) {
      this.loading = true;
      this.$store
        .dispatch('fetchRcsAgents', { page, q: this.q })
        .then((response) => {
          this.agents = response;
          this.pages = response.last_page;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showFilter() {
      this.$refs['filter-modal'].toggle();
    },
    reload() {
      this.$refs.view.fetch();
    },
    applyFilter() {
      this.form.filters = this.filter;
      this.$nextTick(() => {
        this.reload();
      });
    },
    changeCampaign(route, campaignId) {
      this.filter.campaign_id = campaignId;
      this.$router.push(route);
      this.applyFilter();
    },
    cleanFilter() {
      this.filter = {
        carrier: [],
        status: [],
        from: '',
        to: '',
        segments: '',
        campaign_id: '',
      };
      this.form.filter = {};
      this.reload();
    },
    changeDate(date) {
      if (date) {
        this.form.date.gte = date[0];
        this.form.date.lte = moment(date[1]).format('YYYY-MM-DD 23:59:59');

        this.$nextTick(() => {
          this.reload();
        });
      }
    },
    exportData(format) {
      this.$refs.view.exportData(format);
    },
    fetchCampaigns() {
      this.$store.dispatch('fetchRcsCampaigns').then((response) => {
        this.campaigns = response;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~primevue/resources/themes/saga-blue/theme.css';
@import '~primevue/resources/primevue.min.css';

.table-responsive {
  min-height: 150px;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover~.calendar_icon,
.calendar_form:focus-within~.calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}
</style>
