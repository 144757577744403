<template>
  <button class="btn-whatsapp">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'WhatsappButton',
};
</script>

<style lang="scss" scoped>
.btn-whatsapp {
  border-radius: 7.5px;
  box-shadow: 0 1px .5px rgba(11, 20, 26, .13);
  margin-right: 1.5px;
  font-size: .875rem;
  line-height: 20px;
  background-color: white;
  color: #009de2;
  align-items: center;
  margin-left: 1.5px;
  height: 36px;
  min-width: calc(25% - 3px);
  border: 0;
  margin-top: 3px;
}
</style>
