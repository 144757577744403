<template>
  <div class="whatsapp-bubble">
    <template v-if="header">
      <div v-if="header.type === 'TEXT'" class="whatsapp-text-header">{{ header.body }}</div>
      <div v-if="header.type === 'IMAGE'" class="whatsapp-text-header">
        <img :src="header.example_parameter" />
      </div>
    </template>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'WhatsappBubble',
  props: {
    header: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.whatsapp-bubble {
  background-color: white;
  box-shadow: 0 1px .5px rgba(11, 20, 26, .13);
  max-width: 75%;
  border-radius: 7.5px;
  color: #111b21;
  font-size: 14.2px;
  line-height: 19px;
  padding: 8px;

  .whatsapp-text-header {
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    color: var(--primary-strong);
  }
}
</style>
