<template>
  <div class="chart-wrapper">
    <div class="chart-opt">
      <span>{{ title }}</span>
      <button class="btn btn-light calendar">
        <span class="material-icons-outlined title">calendar_today</span>
        <date-range-picker class="form-control" :format="'YYYY-MM-DD'" :options="options" @change="changeDate" />
      </button>
      <span class="material-symbols-outlined icon_opt" :class="[type === 'line' ? 'active' : '']"
        @click="changeType('line')">
        show_chart
      </span>
      <span class="material-symbols-outlined icon_opt" :class="[type === 'pie' ? 'active' : '']"
        @click="changeType('pie')">
        data_usage
      </span>
    </div>
    <div class="block-el chart-item" :class="{ 'w-100': !noData }">
      <!-- <code>{{this.dataTabs.pie.labels}}</code> -->
      <!-- <div v-show="loading" class="qt-block-ui" /> -->
      <div v-show="loadingTabs" class="loading min-h-300">
        <LoadingAnim />
      </div>
      <div v-show="!loadingTabs" class="chart" :class="noData ? '' : 'd-none'">
        <apexchart :class="[type === 'line' ? '' : 'd-none']" ref="lineChart" height="400" :options="line"
          :series="series_line"></apexchart>
        <apexchart :class="[type === 'pie' ? '' : 'd-none']" ref="pieChart" height="400" :options="pie"
          :series="series_pie"></apexchart>
      </div>
      <div class="center-lottie d-block h-400" v-if="!noData && !loadingTabs">
        <LottieAnimNoData />
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
import axios from '@/plugins/axios';

export default {
  name: 'Chart',
  components: {
    LoadingAnim,
    LottieAnimNoData,
  },
  props: {
    format: {
      type: String,
    },
    title: {
      type: String,
    },
    data: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    fetchSms: {
      type: Boolean,
    },
  },
  computed: {
    timeWindow() {
      if (moment(this.selectedEndDate).diff(moment(this.selectedStartDate), 'days')) {
        return 'day';
      }
      return 'hour';
    },
  },
  data() {
    const colors = [
      '#F3B415',
      '#F27036',
      '#663F59',
      '#6A6E94',
      '#4E88B4',
      '#00A7C6',
      '#18D8D8',
      '#A9D794',
      '#46AF78',
      '#A93F55',
      '#752de6',
      '#2176FF',
      '#33A1FD',
      '#7A918D',
      '#BAFF29',
    ];

    return {
      noData: true,
      dataTabs: [],
      dataSms: [],
      selectedStartDate: '',
      selectedEndDate: '',
      selectedTimeWindow: '',
      timeout: 0,
      loadingTabs: false,
      loadingChannels: false,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('DD/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      form: {
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
        },
        filters: {
          agentId: '',
          channels: [],
          tabs: [],
          tags: [],
          protocol: '',
        },
      },
      filtersSms: {
        carrier: [],
        status: [],
        from: '',
        to: '',
        segments: '',
        campaign_id: '',
      },
      type: 'pie',
      line: {
        chart: {
          type: 'line',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false,
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          formatter: (l) => l.charAt(0).toUpperCase() + l.slice(1),
        },
        markers: {
          size: 5,
        },
        colors,
      },
      series_line: [],
      pie: {
        chart: { type: 'donut' },
        stroke: { width: 0 },
        plotOptions: {
          pie: {
            // customScale: 0.8,
            donut: {
              size: '50%',
              labels: {
                show: true,
              },
            },
          },
        },
        legend: {
          position: 'right',
          horizontalAlign: 'center',
        },
        colors,
      },
      series_pie: [],
      stroke: {
        curve: 'straight',
      },
    };
  },
  watch: {
    format(newFormat) {
      this.type = newFormat;
    },
    loading(newLoading) {
      if (!newLoading) this.render();
    },
  },
  mounted() {
    this.fetch();
    console.log('Fetch true: ', this.fetchSms);
    // this.fetchTariffedMessages();
  },
  methods: {
    fetch() {
      this.loadingTabs = true;
      this.loadingChannels = true;
      clearTimeout(this.timeout);

      // Prevent multiple requests
      this.timeout = setTimeout(() => this.fetchAll(), 200);
    },
    changeDate(date) {
      if (date) {
        this.form.date.gte = date[0];
        this.form.date.lte = moment(date[1]).format('YYYY-MM-DD 23:59:59');

        this.$nextTick(() => {
          this.fetch();
        });
      }
    },
    changeType(type) {
      this.type = type;
    },
    render() {
      console.log('Chart::render', this.dataTabs, '\nLength: ', this.dataTabs.length);
      const lineSeries = [];
      const pieSeries = [];
      const labels = [];

      this.type = this.format || 'line';

      this.dataTabs.forEach((insight) => {
        let total = 0;
        lineSeries.push({
          name: insight.group_by,
          data: insight.values,
        });
        insight.values.forEach((value) => {
          total += value[1];
        });
        labels.push(insight.group_by);
        pieSeries.push(total);
      });
      this.$refs.lineChart.updateSeries(lineSeries);
      this.$refs.pieChart.updateSeries(pieSeries);
      this.series_pie = pieSeries;
      // this.pie.labels = labels;
      this.pie = {
        labels,
      };

      console.log('chart::', {
        lineSeries,
        pieSeries,
        labels,
      });
      if (this.dataTabs.length > 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    },
    renderSms() {
      console.log('Chart SMS::render', this.dataSms, '\nLength: ', this.dataSms.length);
      const lineSeries = [];
      const pieSeries = [];
      const labels = [];

      this.type = this.format || 'line';

      this.dataSms.forEach((insight) => {
        let total = 0;
        lineSeries.push({
          name: insight.group_by,
          data: insight.values,
        });
        insight.values.forEach((value) => {
          total += value[1];
        });
        labels.push(insight.group_by);
        pieSeries.push(total);
      });
      this.$refs.lineChart.updateSeries(lineSeries);
      this.$refs.pieChart.updateSeries(pieSeries);
      this.series_pie = pieSeries;
      // this.pie.labels = labels;
      this.pie = {
        labels,
      };

      console.log('chart::', {
        lineSeries,
        pieSeries,
        labels,
      });
      if (this.dataSms.length > 1) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    },
    fetchAll() {
      if (this.fetchSms) {
        console.log('Fetching TariffedMeessages...');
        this.fetchTariffedMessages(this.filtersSms);
      } else {
        this.tabulation();
      }
      // this.channel();
      // this.finished();
    },
    tabulation() {
      this.selectedStartDate = this.form.date.gte;
      this.selectedEndDate = this.form.date.lte;
      this.selectedTimeWindow = this.timeWindow;
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        time_window: this.timeWindow,
        filter: {
          channels: this.channels,
          tabs: this.tabs,
          tags: this.tags,
        },
        group_by: 'tabulation',
      };
      console.log('Test infos\nStart date: ', this.selectedStartDate, '\nEnd date: ', this.selectedEndDate, '\nTime Window: ', this.selectedTimeWindow);
      axios()
        .post('webchat/statistics/data', {
          start_date: this.selectedStartDate,
          end_date: this.selectedEndDate,
          time_window: this.selectedTimeWindow,
          group_by: 'tabulation',
          filter: this.form.filters,
        })
        .then((resp) => {
          // parse data
          this.dataTabs = resp.data.map((item) => {
            const groupBy = item?.['group_by'];

            if (!groupBy || groupBy === 'null' || groupBy === 'GENERIC') {
              item.group_by = 'Não tabulado';
              item.id = 'generic';
            }

            console.log(
              'webchat/statistics/data',
              JSON.parse(JSON.stringify(item)),
            );

            item.values = item.values.map((value) => [value.date, value.count]);

            return item;
          });

          console.log(
            'webchat/statistics/data',
            JSON.parse(JSON.stringify(this.dataTabs)),
          );

          // console.log('chart::2', this.date);

          // this.series_line = Object.values(series);
          this.loadingTabs = false;
          this.render();
        });
    },
    fetchTariffedMessages(filter) {
      this.selectedStartDate = this.form.date.gte;
      this.selectedEndDate = this.form.date.lte;
      console.log('dataSms: ', this.dataSms, '\nDate strings \nstartDate:', this.selectedStartDate, 'endDate: ', this.selectedEndDate, 'filter: ', filter);
      this.charged = 0;
      this.$store
        .dispatch('fetchSmsInsights', {
          start_date: this.selectedStartDate,
          end_date: this.selectedEndDate,
          time_window: this.timeWindow,
          group_by: 'status',
          filter,
        })
        .then((response) => {
          this.loading = false;
          console.log('Tariffed: ', response);
          Object.entries(response).forEach((status) => {
            if (status[0] === 'failed') {
              this.failures = status[1];
            } else {
              this.charged += status[1];
            }
          });
          this.dataSms = response.map((insight) => {
            insight.group_by = this.$tc(`app.${insight.group_by}`);
            return insight;
          });
          this.loadingTabs = false;
          this.renderSms();
        });
    },
    channel() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        time_window: this.timeWindow,
        filter: {
          channels: this.channels,
          tabs: this.tabs,
          tags: this.tags,
        },
        group_by: 'channel',
      };

      axios()
        .post('webchat/statistics/data', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'channel',
          filter: this.filters,
        })
        .then((resp) => {
          console.log('webchat/statistics/data', resp.data);
          // parse data
          this.dataChannels = resp.data.map((item) => {
            const groupBy = item?.['group_by'];

            if (groupBy === 'rcs') {
              item.group_by = 'RCS';
            }

            if (groupBy.indexOf('-java') !== -1) {
              item.group_by = groupBy.replace(/-java$/, '');
            }

            item.values = item.values.map((value) => [value.date, value.count]);
            return item;
          });

          // console.log('chart::2', this.date);

          // this.series_line = Object.values(series);
          this.loadingChannels = false;
        });
    },
    finished() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        time_window: this.timeWindow,
        filter: {
          channels: this.channels,
          tabs: this.tabs,
          tags: this.tags,
        },
        group_by: 'channel',
      };

      axios()
        .post('webchat/statistics/finished', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'channel',
          filter: this.filters,
        })
        .then((resp) => {
          // this.data = resp;
          // this.series_line = Object.values(series);
          // console.log('webchat/statistics/finished', resp.data);

          this.resumeEvaluation = (resp.data?.evaluation || 0)
            .toFixed(1)
            .replace(/\.[0]*$/, '');
          this.resumeFinished = (resp.data?.finished || 0)
            .toFixed(1)
            .replace(/\.[0]*$/, '');
        });
    },
    exportData(format) {
      let chartComponent;
      if (this.type === 'line') {
        chartComponent = this.$refs.lineChart.chart;
      } else {
        chartComponent = this.$refs.pieChart.chart;
      }

      if (format === 'csv') {
        chartComponent.ctx.exports.exportToCSV({
          series: chartComponent.w.config.series,
          columnDelimiter: ';',
        });
      } else if (format === 'png') {
        chartComponent.ctx.exports.exportToPng(chartComponent.ctx);
      } else {
        chartComponent.ctx.exports.exportToSVG(chartComponent.ctx);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chart-opt::v-deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  // margin-bottom: 2.5rem;
  padding-top: .5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.chart-opt span:first-of-type:not(.material-icons-outlined) {
  font-size: 1.25rem;
  margin-right: auto;
  font-weight: 400;
}

.icon_opt {
  background-color: #f7f7f7;
  padding: 4px;
  border-radius: 5px;
  font-size: 32px;
  max-width: 42px;
  cursor: pointer;
  color: var(--gray-font-color);
  transition: 0.5s;
}

.icon_opt.active {
  color: var(--clr-yup-purple);
}

.icon_opt:hover {
  background-color: #e7e7e7;
}

.darkmode .icon_opt {
  background-color: var(--background-3);
}

::v-deep .btn {
  &.calendar {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 1.5rem;
    padding: 0.5rem;

    input {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
}

::v-deep {
  .apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
    justify-content: center;
  }

  .chart-item {
    width: 540px;
    height: fit-content !important;
    margin-bottom: auto;
    margin-top: auto;

    @media (min-width: 1360px) and (max-width: 1600px) {
      width: auto;
    }
  }

  .center-lottie {
    display: flex !important;

    .svg_wrapper {
      margin: auto !important;
    }
  }
}
</style>
