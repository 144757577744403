<template>
  <div class="content">
    <PageHeader :title="$tc('app.monitor', 2)" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <SmsMonitorAnalytics />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <CardComponent variant="tertiary">
            <template #header>
              {{ $tc('app.report', 2) }}
              <ul class="actions top-right">
                <li>
                  <a href="javascript:void(0)" @click="fetchResults(1)"><i class="icon dripicons-clockwise"></i></a>
                </li>
              </ul>
            </template>
            <TableComponent :busy="loading" :fields="fields" :items="testResults">
              <template #cell(message_status)="slotProps">
                <BadgeComponent v-if="slotProps.data === 'delivered'" variant="success">{{ $tc('app.delivered') }}
                </BadgeComponent>
                <BadgeComponent v-else-if="slotProps.data === 'sent'" variant="warning">{{ $tc('app.sent') }}
                </BadgeComponent>
                <BadgeComponent v-else variant="danger">{{ $tc(`app.${slotProps.data}`) }}</BadgeComponent>
              </template>
              <template #cell(test_status)="slotProps">
                <BadgeComponent v-if="slotProps.data === 'received'" variant="success">{{ $tc('app.received') }}
                </BadgeComponent>
                <BadgeComponent v-else-if="slotProps.data === 'waiting'" variant="warning">{{ $tc('app.waiting') }}
                </BadgeComponent>
                <BadgeComponent v-else variant="danger">{{ $tc(`app.${slotProps.data}`) }}</BadgeComponent>
              </template>
            </TableComponent>
            <PaginationComponent v-model="form.page" :total-pages="pages" @change="fetchResults" />
          </CardComponent>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/PageHeader.vue';
import ProviderService from '@/services/admin/provider.service';
import CardComponent from '@/components/CardComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';
import PaginationComponent from '@/components/PaginationComponent.vue';
import SmsMonitorAnalytics from '@/components/admin/sms/SmsMonitorAnalytics.vue';

export default {
  name: 'Monitor',
  components: {
    PageHeader,
    CardComponent,
    TableComponent,
    BadgeComponent,
    PaginationComponent,
    SmsMonitorAnalytics,
  },
  data() {
    return {
      loading: false,
      fetched: false,
      expanded: false,
      providers: [],
      form: {
        page: 1,
        filter: {
          created_at: {
            gte: moment().format('YYYY-MM-DD'),
            lte: moment().format('YYYY-MM-DD 23:59:59'),
          },
        },
      },
      fields: [
        {
          key: 'created_at',
          formatter: (value) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
          key: 'id',
        },
        {
          key: 'message_status',
        },
        {
          key: 'test_status',
        },
        {
          key: 'text_sent',
        },
        {
          key: 'provider.name',
          label: this.$tc('app.provider', 1),
        },
        {
          key: 'test_number.phone',
          label: this.$tc('app.phone', 1),
        },
        {
          key: 'dlr_delay',
          formatter: (value) => this.formatTime(value),
        },
        {
          key: 'receipt_delay',
          formatter: (value) => this.formatTime(value),
        },
      ],
      testResults: [],
      pages: 1,
      running: false,
      updating: false,
      timeout: 0,
    };
  },
  mounted() {
    this.running = true;
    this.fetch(1);
    this.fetchResults(1);
  },
  beforeDestroy() {
    this.running = false;
    clearTimeout(this.timeout);
  },
  methods: {
    reFeatch() {
      if (this.running) {
        this.timeout = setTimeout(() => this.fetch(), 60000);
      }
    },
    fetch(page = 1) {
      this.now = moment().unix();
      this.form.page = page;
      this.fetched = false;
      ProviderService.getProviders(this.form).then((response) => {
        this.fetched = true;
        this.providers = response.data;
        this.pages = response.last_page;
        return this.reFeatch();
      }).then((response) => this.refresh()).catch((error) => {
        this.content = error;
      });
    },
    fetchResults(page = 1) {
      this.loading = true;
      this.$store.dispatch('fetchTestResults', this.form).then((response) => {
        this.testResults = response.data;
        this.pages = response.last_page;
      }).finally(() => {
        this.loading = false;
      });
    },
    refresh() {
      return this.$store.dispatch('auth/refresh');
    },
    expand() {
      this.expanded = !this.expanded;
    },
    formatTime(seconds) {
      const duration = moment.duration(seconds, 'seconds');

      const hours = duration.hours();
      const minutes = duration.minutes();
      const remainingSeconds = duration.seconds();

      const parts = [];
      if (hours > 0) parts.push(`${hours}h`);
      if (minutes > 0) parts.push(`${minutes}m`);
      if (remainingSeconds > 0 || parts.length === 0) parts.push(`${remainingSeconds}s`);

      return parts.join(' ');
    },
  },
};
</script>
<style scoped lang="scss">
.btn-remove {
  color: #fff;
  margin-bottom: 5px;
}

.btn-new {
  margin-bottom: 5px;
}

.color-white {
  color: #fff;
}

.form-search {
  float: right;
  width: 100%;
}

.form-search button {
  border: solid 1px #ccc;
  border-left: 0px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  height: 35px;
}

.form-search button i {
  font-size: 16px;
}

.w-100 {
  width: 100% !important;
}
</style>
