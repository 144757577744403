<template>
  <div class="content">
    <!-- <PageHeader :breadcrumbLinks="breadcrumbLinks" title="Omni Business" subtitle="Monitoramento"/> -->
    <PageHeader :breadcrumbLinks="breadcrumbLinks" title="Chat" subtitle="Monitoramento" />
    <div class="page-content container-fluid">
      <!-- main -->
      <div class="row main">
        <div class="col-md-6 col-xl-3">
          <div class="card p-3 card-info-header">
            <div>
              <div>
                <Message />
              </div>
              <strong>{{ open }}</strong>
              <h3>Atendimentos</h3>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card p-3 card-info-header">
            <div>
              <div>
                <Clock />
              </div>
              <strong>{{ queue }}</strong>
              <h3>Em espera</h3>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card p-3 card-info-header">
            <div>
              <div>
                <Coffee />
              </div>
              <strong>{{ maxWait | roundTime }}</strong>
              <h3>Maior tempo de espera</h3>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card p-3 card-info-header">
            <div>
              <div>
                <Attendance />
              </div>
              <strong>{{ agentsOn }}</strong>
              <h3>Agentes Online</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="table-container">
        <table>
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">{{ $tc('app.department', 1) }}</th>
              <th role="columnheader" scope="col">{{ $tc('app.active') }}</th>
              <th role="columnheader" scope="col">{{ $tc('app.in_queue') }}</th>
              <th role="columnheader" scope="col">{{ $tc('app.average_await') }}</th>
              <th role="columnheader" scope="col">{{ $tc('app.max_await') }}</th>
              <th role="columnheader" scope="col">{{ $tc('app.online_agent', 2) }}</th>
              <th role="columnheader" scope="col">{{ $tc('app.unavailable_agent', 2) }}</th>
              <th role="columnheader" scope="col">{{ $tc('app.agent_on_break', 2) }}</th>
              <th role="columnheader" scope="col">{{ $tc('app.offline_agent', 2) }}</th>
              <th role="columnheader" scope="col">{{ $tc('app.channel', 2) }}</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr role="row">
              <td :data-label="$tc('app.department', 1)">
                Todos
              </td>
              <td :data-label="$tc('app.active', 2)">{{ open }}</td>
              <td :data-label="$tc('app.in_queue')">{{ queue }}</td>
              <td :data-label="$tc('app.average_await')">{{ averageWait | roundTime }}</td>
              <td :data-label="$tc('app.max_await')">{{ maxWait | roundTime }}</td>
              <td :data-label="$tc('app.online_agent', 2)">{{ agentsOn }}</td>
              <td :data-label="$tc('app.unavailable_agent', 2)">{{ agentsUnavailable }}</td>
              <td :data-label="$tc('app.agent_on_break', 2)">{{ agentsBreak }}</td>
              <td :data-label="$tc('app.offline_agent', 2)">{{ agentsOff }}</td>
              <td :data-label="$tc('app.channel', 2)"></td>
            </tr>
            <template v-for="(entry, i) in $live.administer">
              <tr :key="i" role="row">
                <td :data-label="$tc('app.department', 1)">
                  <div>
                    <i class="fas fa-globe" v-if="entry.generic"></i>
                    <i class="fas fa-users" v-else></i>
                    {{ entry.generic ? 'Geral' : entry.department }}
                  </div>
                </td>
                <td :data-label="$tc('app.active', 2)">
                  <div>{{ entry.open_room }}</div>
                </td>
                <td :data-label="$tc('app.in_queue')">
                  <div>{{ entry.queue_size }}</div>
                </td>
                <td :data-label="$tc('app.average_await')">
                  <div>{{ entry.average_wait | roundTime }}</div>
                </td>
                <td :data-label="$tc('app.max_await')">
                  <div>{{ entry.maximum_wait | roundTime }}</div>
                </td>
                <td :data-label="$tc('app.online_agent', 2)">
                  <div>{{ entry.agent_available }}</div>
                </td>
                <td :data-label="$tc('app.unavailable_agent', 2)">
                  <div>{{ entry.agent_unavailable }}</div>
                </td>
                <td :data-label="$tc('app.agent_on_break', 2)">
                  <div>{{ entry.agent_break }}</div>
                </td>
                <td :data-label="$tc('app.offline_agent', 2)">
                  <div>{{ entry.agent_off }}</div>
                </td>
                <td class="drop-table-icon" :data-label="$tc('app.channel', 2)">
                  <div class="display-flex">
                    <template v-for="(sEntry, j) in entry.channels">
                      <span class="icon-channel" v-b-tooltip.hover :title="`${sEntry.size} atendimento(s)`" :key="j">
                        <i class="fas fa-globe" v-if="sEntry.channel === 'widget'"></i>
                        <i class="fab fa-facebook-messenger" v-else-if="sEntry.channel === 'messenger'"></i>
                        <i class="fab fa-whatsapp"
                          v-else-if="sEntry.channel === 'whatsapp' || sEntry.channel === 'whatsapp-java'"></i>
                        <i class="fab fa-google" v-else-if="sEntry.channel === 'rcs'"></i>
                        <i class="fab fa-telegram" v-else-if="sEntry.channel === 'telegram'"></i>
                        <i class="fab fa-instagram" v-else-if="sEntry.channel === 'instagram'"></i>
                        <i class="fas fa-envelope" v-else-if="sEntry.channel === 'email'"></i>
                        <GoogleBusinessIcon v-else-if="sEntry.channel === 'google-business'" />
                        <i class="fas fa-question" v-else></i>
                        <span class="sr-only">{{ sEntry.channel }}</span>
                      </span>
                    </template>
                    <span class="material-symbols-outlined c-pointer icon-down" v-if="entry.contentVisible"
                      @click="showHidden(entry)">
                      keyboard_arrow_up
                    </span>
                    <span class="material-symbols-outlined c-pointer icon-down" v-if="!entry.contentVisible"
                      @click="showHidden(entry)">
                      keyboard_arrow_down
                    </span>
                  </div>
                </td>
              </tr>
              <template v-if="entry.contentVisible && entry.agents.length > 0">
                <template v-for="(agent, l) in entry.agents">
                  <tr :key="(l + 1)" class="no-border">
                    <td style="justify-content: flex-start;">
                      <div class="profile-monitor">
                        <div class="profile-photo" style="display: none;">
                          <figure>
                            <div class="fallback-image">
                              {{ initials(agent.name, '') }}
                            </div>
                          </figure>
                        </div>
                        <h4>{{ agent.name }}</h4>
                      </div>
                    </td>
                    <td :data-label="$tc('app.active', 2)">
                      <div>{{ agent.open_room }}</div>
                    </td>
                    <td :data-label="$tc('app.in_queue')">
                      <div>{{ agent.queue_size }}</div>
                    </td>
                    <td :data-label="$tc('app.average_await')">
                      <div>{{ agent.average_wait | roundTime }}</div>
                    </td>
                    <td :data-label="$tc('app.max_await')">
                      <div>{{ agent.maximum_wait | roundTime }}</div>
                    </td>
                    <td>
                      <div>
                        <span v-if="agent.active" class="badge success">Disponível</span>
                        <span v-else class="badge danger">Offline</span>
                      </div>
                    </td>
                    <td>
                      <div></div>
                    </td>
                    <td>
                      <div></div>
                    </td>
                    <td>
                      <div></div>
                    </td>
                    <td>
                      <div style="display: none;">
                        <template v-for="(sEntry, j) in entry.channels">
                          <span v-b-tooltip.hover class="icon-channel" :key="j">
                            <i class="fas fa-globe" v-if="sEntry.channel === 'widget'"></i>
                            <i class="fab fa-facebook-messenger" v-else-if="sEntry.channel === 'messenger'"></i>
                            <i class="fab fa-whatsapp"
                              v-else-if="sEntry.channel === 'whatsapp' || sEntry.channel === 'whatsapp-java'"></i>
                            <i class="fab fa-google" v-else-if="sEntry.channel === 'rcs'"></i>
                            <i class="fab fa-telegram" v-else-if="sEntry.channel === 'telegram'"></i>
                            <i class="fab fa-instagram" v-else-if="sEntry.channel === 'instagram'"></i>
                            <i class="fas fa-envelope" v-else-if="sEntry.channel === 'email'"></i>
                            <GoogleBusinessIcon v-else-if="sEntry.channel === 'google-business'" />
                            <i class="fas fa-question" v-else></i>
                            <span class="sr-only">{{ sEntry.channel }}</span>
                          </span>
                        </template>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
              <template v-if="entry.contentVisible && entry.agents.length == 0">
                <template>
                  <tr v-bind:key="(i + 1)" class="no-border">
                    <td>
                      <div>
                        Nenhum agente encontrado
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import GoogleBusinessIcon from '@/components/GoogleBusinessIcon.vue';
import Clock from '@/components/rebranding/animLottie/Clock.vue';
import Coffee from '@/components/rebranding/animLottie/Coffee.vue';
import Attendance from '@/components/rebranding/animLottie/Attendance.vue';
import Message from '@/components/rebranding/animLottie/Message.vue';

export default {
  name: 'LiveDashboard',
  components: {
    PageHeader,
    GoogleBusinessIcon,
    Attendance,
    Clock,
    Coffee,
    Message,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/live/reports/stat',
        2: '/live/dashboard',
      },

      optionsPlayer: {
        minimizable: false,
        playerSize: 'standard',
        backgroundColor: '#fff',
        backgroundStyle: 'color',
        theme: {
          controlsView: 'standard',
          active: 'light',
          light: {
            color: '#3D4852',
            backgroundColor: '#fff',
            opacity: '0.7',
          },
          dark: {
            color: '#fff',
            backgroundColor: '#202020',
            opacity: '0.7',
          },
        },
      },
    };
  },
  computed: {
    open() {
      let sum = 0;

      this.$live.administer.forEach((entry) => {
        sum += entry.open_room;
      });

      return sum;
    },
    queue() {
      let sum = 0;

      this.$live.administer.forEach((entry) => {
        sum += entry.queue_size;
      });

      return sum;
    },
    averageWait() {
      let check = 0;

      this.$live.administer.forEach((entry) => {
        if (entry.average_wait > check) check = entry.average_wait;
      });

      return check;
    },
    maxWait() {
      let check = 0;

      this.$live.administer.forEach((entry) => {
        if (entry.maximum_wait > check) check = entry.maximum_wait;
      });

      return check;
    },
    agentsOn() {
      return this.$live.administer?.[0]?.['agent_available'] === undefined ? 0 : this.$live.administer?.[0]?.['agent_available'];
    },
    agentsUnavailable() {
      return this.$live.administer?.[0]?.['agent_unavailable'];
    },
    agentsBreak() {
      return this.$live.administer?.[0]?.['agent_break'];
    },
    agentsOff() {
      return this.$live.administer?.[0]?.['agent_off'];
    },
    agentsTotal() {
      return this.agentsOn + this.agentsUnavailable + this.agentsBreak + this.agentsOff;
    },
  },
  filters: {
    roundTime: (value) => {
      const days = Math.floor(value / 1440);

      // console.log('roundTime.days', days);

      if (days >= 1) return `${days} dia${days < 2 ? '' : 's'}`;

      const hours = Math.floor(value / 60);

      // console.log('roundTime.hours', hours);

      if (hours >= 1) return `${hours} hora${hours < 2 ? '' : 's'}`;

      // console.log('roundTime.mins', value);

      return `${Math.floor(value)} min${value < 2 ? '' : 's'}`;
    },
  },
  methods: {
    showHidden(entry) {
      entry.contentVisible = !entry.contentVisible;
      this.$forceUpdate();
      console.log(entry);
    },
    initials(firstname, lastname) {
      (firstname === null ? firstname = '' : firstname); // Avoiding displaying 'null' string
      (lastname === null ? lastname = '' : lastname); // Avoiding displaying 'null' string
      const emojiRegex = /\p{Emoji}/u;
      // console.log('Firstname: ', firstname, '\nLastname: ', lastname);
      let fullname = '';
      if (firstname) {
        firstname = !firstname.match(emojiRegex) ? firstname.charAt(0).toUpperCase() : firstname.replace(emojiRegex, '').charAt(0).toUpperCase();
      }
      if (lastname) {
        lastname = !lastname.match(emojiRegex) ? lastname.charAt(0).toUpperCase() : lastname.replace(emojiRegex, '').charAt(0).toUpperCase();
      }
      fullname = firstname + lastname;
      return fullname;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main .card {
  position: relative;
  height: 150px;
  overflow: hidden;

  h3 {
    font-size: 90%;
    font-weight: bold;
  }

  &>div {
    position: relative;
    z-index: 5;
  }

  .bg {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 4;
    opacity: .15;
    font-size: 64pt;
  }

  &>div>strong {
    display: block;
    font-size: 200%;
  }

  .actives {
    * {
      font-size: 92%;
    }

    .status {
      vertical-align: middle;
      display: inline-block;
      border-radius: 8px;
      width: 12px;
      height: 12px;
    }
  }

  .progress {
    button::v-deep {
      border-radius: 0;
      border: none;
      padding: 0;
      margin: 0;
      height: auto !important;
    }
  }
}

table {

  th {
    white-space: nowrap;
  }

  tr> :first-child i {
    text-align: center;
    width: 20px;
  }

  tr.sub> :first-child i {
    margin-left: .4rem;
  }

  .channels {
    button::v-deep {
      background: transparent !important;
      height: 24px !important;
      width: 24px !important;
      min-width: 1px;
      padding: 0;
      margin-right: 2px;

      &::last-child {
        margin-right: 0;
      }
    }
  }

  th[scope=col]:not(:first-child),
  td {
    text-align: center;
  }

  th[scope=row] {
    text-transform: capitalize;
  }
}

.svg_wrapper {
  width: 70px !important;
  height: auto !important;
  margin-top: 15px !important;
  margin-left: 0 !important;
}

.card-info-header {
  text-align: left;
}

.card-info-header h3 {
  color: #595959;
  margin: 0;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.card-info-header {
  height: 200px !important;
}

.card-info-header strong {
  color: #0D0519;
  font-weight: 400 !important;
  font-size: 32px !important;
}

.th-monitor {
  color: var(--gray-font-color);
  font-size: 14px !important;
  border: #fff 1px solid !important;
  padding-top: 30px;
  padding-bottom: 30px;
  background: var(--background-3);
  border-color: var(--table-border) !important;
}

.td-monitor {
  border: 1px solid #fff !important;
  border-color: var(--table-border) !important;
  font-weight: 300;
  color: var(--gray-font-color-2);
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: var(--background-2);
}

.card-title {
  font-size: 26px !important;
  color: var(--foreground);
}

.m-0 {
  margin: 0px !important;
}

.w-100 {
  width: 100% !important;
}

.to-left {
  float: left !important;
}

.input-container {
  float: right;
  position: relative;
  width: 40%;
}

.input-container img {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  margin-top: 10px;
  margin-right: 8px;
  cursor: pointer;
}

.card {
  box-shadow: none;
  border: 1px #E4E7EC solid;
  border-radius: 8px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  position: relative;

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: auto;
    color: #6F6F6F;
    margin-bottom: 1rem;

    thead {
      th {
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        padding: 12px;
        white-space: nowrap;
      }
    }

    tbody {
      tr {
        &:not(:last-child) {}

        td {
          font-size: 14px;
          font-weight: 400;
          padding: 12px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .clickable {
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

.table-container table tbody tr {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
}

.table-container table tbody {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
}

.table-container table thead tr {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 3px solid rgba(107, 35, 220, 0.2);
  margin-bottom: 1rem;
  align-items: center;
  text-align: center;
}

.table-container table tbody tr td {
  width: 100%;
  height: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-container table thead tr th {
  width: 25%;
  text-align: center
}

.no-records-cell {
  width: 100% !important;
  display: flex;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.container-nd {
  width: 100% !important;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  padding: 10px;
  box-sizing: border-box;
}

.spinner {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.spinner-div {
  width: 100%;
  height: 100%;
}

.spinner-border {
  margin: auto;
  color: rgba(107, 35, 220, 0.8) !important;
}

@media (max-width: 1200px) {

  .table-container table tbody tr {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border: 1px solid #E5E5E5;
    border-radius: 6px;
  }

  .table-container table tbody tr td {
    text-align: end;
  }

  table {
    thead {
      display: none;
    }

    td {
      text-align: right;
      position: relative;
      padding-left: 50%;
      height: auto;
      border: none;
      width: 100% !important;
      box-sizing: border-box;

      &:before {
        content: attr(data-label);
        position: absolute;
        left: 16px;
        width: calc(50% - 16px);
        text-align: left;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .container-nd {
    font-size: 14px;
    padding: 5px;
  }

  .no-register {
    flex-direction: column;
    padding: 5px;
  }

  .no-records-cell {
    text-align: center !important;
    padding: 5px;
  }

  .table-container table tbody tr td {
    width: 100% !important;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

.spinner {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.spinner-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

.table-container table tbody .no-border {
  border: none !important;
}

.profile-photo {
  figure {
    margin: 0 auto;
    border-radius: 48px;
    background: #752de6;
    width: 32px;
    height: 32px;
  }

  &:hover {
    color: #fc0;

    &~.profile-more-details {
      opacity: 1;
      z-index: 100;
    }
  }
}

.fallback-image {
  color: #fff;
  font-size: 12px;
  padding-top: 10px;
}

.profile-monitor {
  display: flex;
}

.profile-monitor h4 {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}

.rebranding button i {
  color: #fff;
}

.badge.success {
  background-color: #e1fcef;
  color: #14804a;
}

.badge.danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.drop-table-icon {
  justify-content: flex-end !important;
  text-align: right !important;
}

.icon-down {
  height: auto;
  margin-top: 8px;
}

.display-flex {
  display: flex !important;
}

.icon-channel {
  margin-right: 15px;
  display: block;
  font-size: 20px;
  margin-top: 6px;
}
</style>
