const routes = [
  {
    name: 'LinksList',
    path: 'links',
    component: () => import('../views/link-shortener/List.vue'),
  },
  {
    name: 'LinksReports',
    path: 'reports',
    component: () => import('../views/link-shortener/reports/Index.vue'),
    children: [
      {
        name: 'LinkStatReport',
        path: 'stat',
        component: () => import('../views/link-shortener/reports/Stat.vue'),
      },
    ],
  },
];

export default routes;
