<template>
  <div class="form-group" :class="{ invalid: !isValid }">
    <label v-if="label">{{ label }}</label>
    <div class="input-group">
      <div v-if="$slots.prepend" class="input-group-prepend">
        <slot name="prepend"></slot>
      </div>
      <input v-model="inputValue" :type="type" :value="inputValue" class="form-control" :placeholder="placeholder"
        :required="required" :disabled="disabled" @input="handleInput" @blur="handleBlur" @change="handleChange"
        v-on:keyup="handleKeyUp" />
      <div v-if="$slots.append" class="input-group-append">
        <slot name="append"></slot>
      </div>
    </div>
    <div v-if="!isValid" class="form-text text-danger">
      <template v-for="error in errors">
        {{ error }}
      </template>
    </div>
  </div>
</template>

<script>
import validationRules from '@/rules';

export default {
  name: 'InputComponent',
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: [String, Array],
    },
    variant: {
      type: String,
      default: 'primary',
      validator(value, props) {
        return ['primary', 'secondary', 'tertiary'].includes(value);
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: [String, Array],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      allRules: [],
      inputValue: this.value,
      tokens: {
        '#': { pattern: /\d/ },
        X: { pattern: /[0-9a-zA-Z]/ },
        S: { pattern: /[a-zA-Z]/ },
        A: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
        a: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() },
        '!': { escape: true },
      },
      currentMask: '',
    };
  },
  watch: {
    value(newValue) {
      this.inputValue = this.maskIt(newValue);
    },
  },
  computed: {
    classObject() {
      return [
        this.variant,
        { 'qt-loader qt-loader-mini qt-loader-right': this.loading },
      ];
    },
    isValid() {
      return this.errors.length === 0;
    },
  },
  created() {
    this.allRules = this.rules;
    if (this.required) {
      this.allRules.push('required');
    }
    if (this.type === 'email') {
      this.allRules.push('email');
    }
    if (this.type === 'tel') {
      this.allRules.push('tel');
    }
  },
  mounted() {
    this.inputValue = this.value;
  },
  methods: {
    handleKeyUp(event) {
      this.$emit('keyup', event);
      this.$emit('keyUp', this.inputValue);
    },
    handleClick(e) {
      this.$emit('click', e);
    },
    handleInput(event) {
      if (!event.isTrusted) return;

      const maskedValue = this.maskIt(event.target.value);
      event.target.value = maskedValue;
      this.inputValue = maskedValue;
      this.$emit('input', maskedValue);

      if (!this.isValid) {
        this.validate(maskedValue);
      }
    },
    handleBlur(event) {
      const maskedValue = this.maskIt(event.target.value);
      this.validate(maskedValue);
      this.$emit('input', maskedValue);
    },
    handleChange(event) {
      const maskedValue = this.maskIt(event.target.value);
      this.validate(maskedValue);
      this.$emit('input', maskedValue);
    },
    validate(value) {
      this.errors = [];
      if (this.allRules.length && (value || this.required)) {
        this.errors = [];
        this.allRules.forEach((rule) => {
          rule = rule.split(':');
          if (validationRules[rule[0]]) {
            if (!validationRules[rule[0]].condition(value, rule[1])) {
              const message = validationRules[rule[0]].getMessage();

              if (message) {
                this.errors.push(this.$t(message, [rule[1]]));
              } else {
                this.errors.push('');
              }
            }
          }
        });
        // Validate mask
        if (this.currentMask && !this.errors.length) {
          if (value.length !== this.currentMask.length) {
            const message = validationRules.mask.getMessage();
            this.errors.push(this.$t(message));
          }
        }
      }
    },
    checkValidation() {
      const maskedValue = this.maskIt(this.value);
      this.validate(maskedValue);
    },
    maskIt(value) {
      value = value || '';
      if (this.mask) {
        this.setCurrentMask(value);
        let iMask = 0;
        let iValue = 0;
        let output = '';
        while (iMask < this.currentMask.length && iValue < value.length) {
          let cMask = this.currentMask[iMask];
          const masker = this.tokens[cMask];
          const cValue = value[iValue];
          if (masker && !masker.escape) {
            if (masker.pattern.test(cValue)) {
              output += masker.transform ? masker.transform(cValue) : cValue;
              iMask += 1;
            }
            iValue += 1;
          } else {
            if (masker && masker.escape) {
              iMask += 1; // take the next mask char and treat it as char
              cMask = this.currentMask[iMask];
            }
            output += cMask;
            if (cValue === cMask) iValue += 1; // user typed the same char
            iMask += 1;
          }
        }
        return output;
      }
      return value;
    },
    setCurrentMask(value) {
      if (Array.isArray(this.mask)) {
        const unmaskedValue = value.replace(/\D/g, '');
        for (let i = 0; i < this.mask.length; i += 1) {
          const unmaskedMask = this.mask[i].replace(/[^#XSAa!]/g, '');
          if (unmaskedValue.length <= unmaskedMask.length || i + 1 === this.mask.length) {
            this.currentMask = this.mask[i];
            break;
          }
        }
      } else {
        this.currentMask = this.mask;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$border-width: 2px;

.form-group {
  margin-bottom: 1.62rem;

  &.invalid {
    .input-group {

      .form-control,
      .btn {
        border-color: #ff5c75;
      }
    }
  }

  .form-control {
    height: 45px;
    padding: 12px 16px;
    border-width: $border-width;
    border-radius: 8px;
    border-color: #e5e5e5;

    &:disabled {
      border: 2px solid transparent;
    }
  }

  .form-text {
    font-size: 80%;
  }

  .input-group {
    .form-control {
      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }
    }

    .btn {
      border-color: var(--form-border);
      border-width: $border-width;
      border-style: solid;
      border-radius: 8px;
      height: 45px;

      &.btn-icon {
        font-size: 24px;
      }
    }

    .input-group-prepend {
      .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }
    }

    .input-group-append {
      .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
    }
  }
}

label {
  margin-bottom: 0.62rem;
}
</style>
