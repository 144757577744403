<template>
  <div class="tab-pane fadeIn active" id="profile-about">
    <h5 class="card-title">{{ $t('properties.company') }}</h5>
    <form @submit.prevent="update">
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="name">{{ $t('adm.create.comp-name') }}</label>
          <input type="text" class="form-control" :placeholder="$t('adm.create.comp-name')" v-model="form.name" />
        </div>
        <div class="form-group col-2">
          <label for="name">{{ $t('app.country') }}</label>
          <select v-model="form.country" class="form-control">
            <option v-for="(country, code) in $store.state.countries" :key="code" :value="country.iso">
              {{ country.name }}
            </option>
          </select>
        </div>
        <div v-if="form.country == 'BR'" class="form-group col-2">
          <label for="name">CNPJ</label>
          <input type="text" class="form-control" placeholder="CNPJ" v-model="form.tax_id" v-mask="'##.###.###/####-##'"
            :required="form.type != 'trial'" :disabled="hasTaxId || searchingCompany" @input="searchCompany" />
          <div v-if="searchingCompany" class="preloader pl-xxs pls-primary">
            <svg class="pl-circular" viewBox="25 25 50 50">
              <circle class="plc-path" cx="50" cy="50" r="20"></circle>
            </svg>
          </div>
        </div>
        <div v-else class="form-group col-2">
          <label for="name">Tax ID</label>
          <input type="text" class="form-control" placeholder="Tax ID" v-model="form.tax_id"
            :required="form.type != 'trial'" />
        </div>
        <div class="form-group col-5">
          <label for="name">{{ $t('adm.create.corporate-name') }}</label>
          <input type="text" class="form-control" :placeholder="$t('adm.create.corporate-name')"
            v-model="form.company_name" :required="form.type != 'trial'" />
        </div>
        <div class="form-group col-6">
          <label for="name">{{ $t('adm.create.billing-email') }}</label>
          <input type="email" class="form-control" :placeholder="$t('adm.create.billing-email')"
            v-model="form.billing_email" :required="form.type != 'trial'" />
        </div>
        <div class="form-group col-md-6">
          <label class="control-label text-left">{{
            $t('generic-str.menu.anti-fraud')
            }}</label>
          <select class="form-control" v-model="form.antifraud" required>
            <option selected value="open">
              {{ $tc('generic-str.status.lbl-open', 1) }}
            </option>
            <option value="moderate">
              {{ $tc('generic-str.status.lbl-moderate', 1) }}
            </option>
            <option value="strict">
              {{ $tc('generic-str.status.lbl-strict', 1) }}
            </option>
          </select>
        </div>
      </div>
      <h5 class="card-title">
        {{ $t('generic-str.contract') }}
      </h5>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="name">{{ $t('generic-str.type') }}</label>
          <select class="form-control" v-model="form.type">
            <option selected value="trial">
              {{ $t('acc-settings.trial') }}
            </option>
            <option value="prepaid">
              {{ $t('adm.customer.assign.prepaid') }}
            </option>
            <option value="weekly">
              {{ $t('adm.customer.assign.weekly') }}
            </option>
            <option value="biweekly">
              {{ $t('adm.customer.assign.biweekly') }}
            </option>
            <option value="monthly">
              {{ $t('adm.customer.assign.monthly') }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="name">{{ $t('app.currency') }}</label>
          <select class="form-control" v-model="form.currency" @change="changeCurrency">
            <option v-for="(currency, i) in $store.state.currencies" :key="i" :value="currency.code">
              {{ currency.name }} ({{ currency.symbol }})
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="name">{{ $t('generic-str.funds') }}</label>
          <div class="input-group">
            <input id="balance" :value="customer.balance" v-money="money" class="form-control" style="text-align: right"
              disabled />
            <div class="input-group-append">
              <button class="btn btn-primary btn-icon" type="button" @click="openBalanceModal">
                <span class="material-symbols-outlined">add</span>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-2">
          <label for="name">{{ $t('generic-str.expiration') }}</label>
          <input v-model="form.due_date" type="number" class="form-control" :required="form.type != 'trial'" />
        </div>
        <!--<div v-if="salesExecutives.length" class="form-group col-md-4">
          <label for="name">{{
              $t('home.invite-card.select.sale-executive')
            }}</label>
          <select-input
            v-if="$store.state.account.role == 'owner' || form.sales_id"
            label="name"
            :reduce="(sales) => sales.id"
            v-model="form.sales_id"
            :options="salesExecutives"
            :clearable="true"
            :disabled="$store.state.account.role != 'owner'"
          />
          <button v-else class="btn btn-success form-control" @click="join">
            {{ $t('generic-str.join-2') }}
          </button>
        </div>-->
      </div>
      <h5 class="card-title">
        {{ $t('app.address') }}
      </h5>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="name">{{ $t('generic-str.zipcode') }}</label>
          <input type="tel" class="form-control" :placeholder="$t('generic-str.zipcode')" v-model="form.postal_code"
            v-mask="'#####-###'" :required="form.type != 'trial'" />
          <div v-if="searching" class="preloader pl-xxs pls-primary loading-cep">
            <svg class="pl-circular" viewBox="25 25 50 50">
              <circle class="plc-path" cx="50" cy="50" r="20" />
            </svg>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="name">{{ $t('app.address') }}</label>
          <input type="text" class="form-control" :placeholder="$t('app.address')" v-model="form.street"
            :required="form.type != 'trial'" />
        </div>
        <div class="form-group col-md-4">
          <label for="name">{{ $tc('generic-str.lbl-number', 1) }}</label>
          <input type="number" class="form-control" :placeholder="$tc('generic-str.lbl-number', 1)"
            v-model="form.number" :required="form.type != 'trial'" />
        </div>
        <div class="form-group col-md-4">
          <label for="name">{{ $t('generic-str.complement') }}</label>
          <input type="text" class="form-control" :placeholder="$t('generic-str.complement')"
            v-model="form.complement" />
        </div>
        <div class="form-group col-md-4">
          <label for="name">{{ $t('generic-str.neighborhood') }}</label>
          <input type="text" class="form-control" :placeholder="$t('generic-str.neighborhood')" v-model="form.district"
            :required="form.type != 'trial'" />
        </div>
        <div class="form-group col-md-4">
          <label for="name">{{ $t('generic-str.city') }}</label>
          <input type="text" class="form-control" :placeholder="$t('generic-str.city')" v-model="form.city"
            :required="form.type != 'trial'" />
        </div>
        <div class="form-group col-md-4">
          <label for="name">{{ $t('generic-str.state') }}</label>
          <select v-model="form.province" class="form-control">
            <option v-for="(province, code) in $store.state.provinces" :key="code" :value="code">
              {{ province }}
            </option>
          </select>
        </div>
      </div>
      <button type="submit" class="btn btn-primary">
        {{ $t('app.save') }}
      </button>
    </form>
    <modal ref="balance-modal" size="sm">
      <h2 class="text-center m-b-10">Saldo Adicional</h2>
      <div class="row m-t-30">
        <div class="col-12">
          <div class="form-group">
            <input type="text" class="form-control" id="demoTextInput1" v-money="money" v-model="newBalance" />
          </div>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between w-100">
          <button @click="updateBalance" type="submit" class="btn btn-primary">
            {{ $t('app.apply') }}
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import UserService from '@/services/user.service';
// import SelectInput from '@/components/SelectInput';
import Modal from '@/components/Modal.vue';
// @ is an alias to /src
export default {
  name: 'EditCustomer',
  components: {
    Modal,
    // SelectInput,
  },
  props: ['customer'],
  data() {
    const form = JSON.parse(JSON.stringify(this.customer));

    let hasTaxId = false;

    if (form.tax_id) {
      hasTaxId = true;
      setTimeout(() => {
        this.hasTaxId = false;
      }, 800);
    }

    return {
      searchingCompany: false,
      salesExecutives: [],
      searching: false,
      newBalance: 0,
      form,
      hasTaxId,
    };
  },
  computed: {
    money() {
      let prefix;
      let precision;

      this.$store.state.currencies.forEach((currency) => {
        if (currency.code === this.form.currency) {
          prefix = `${currency.symbol} `;
          precision = currency.decimalDigits * 2;
        }
      });

      return {
        decimal: ',',
        thousands: '.',
        prefix,
        precision,
        masked: false,
      };
    },
  },
  created() {
    this.fetchSalesExecutives();
  },
  methods: {
    searchAddress() {
      if (this.form.postal_code && this.form.postal_code.length === 9) {
        this.searching = true;
        axios
          .get(`https://viacep.com.br/ws/${this.form.postal_code}/json/`)
          .then((response) => {
            this.searching = false;
            this.form.street = response.data.logradouro;
            this.form.district = response.data.bairro;
            this.form.city = response.data.localidade;
            this.form.province = response.data.uf;
          });
      }
    },
    openBalanceModal() {
      this.$refs['balance-modal'].toggle();
    },
    searchCompany() {
      if (this.form.tax_id.length >= 18 && !this.searchingCompany && !this.hasTaxId) {
        this.searchingCompany = true;
        this.$store
          .dispatch('searchCompany', { taxId: this.form.tax_id })
          .then((response) => {
            this.form.postal_code = response.address.postalCode;
            this.form.street = response.address.street;
            this.form.number = response.address.number;
            this.form.complement = response.address.complement;
            this.form.district = response.address.district;
            this.form.city = response.address.city;
            this.form.province = response.address.province;
            this.form.company_name = response.companyName;
            this.form.billing_email = response.email;
          })
          .catch((error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code
              }`,
              content: error.message,
              type: 'danger',
            });
          })
          .finally(() => {
            this.searchingCompany = false;
          });
      }
    },
    changeCurrency() {
      this.$store.state.currencies.forEach((currency) => {
        if (currency.code === this.form.currency) {
          this.money.prefix = `${currency.symbol} `;
          this.money.precision = currency.decimalDigits * 2;
        }
      });
    },
    fetchSalesExecutives() {
      UserService.getUsers({
        role: 'sales',
      }).then(
        (salesExecutives) => {
          this.salesExecutives = salesExecutives;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    update() {
      this.updating = true;
      this.$store
        .dispatch('updateCustomer', {
          customerId: this.form.id,
          input: this.form,
        })
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('adm.customer.updated'),
              type: 'success',
            });
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code
              }`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.updating = false;
        });
    },
    updateBalance() {
      this.updating = true;
      this.$store
        .dispatch('updateCustomer', {
          customerId: this.form.id,
          input: {
            newBalance: this.newBalance,
          },
        })
        .then(
          (customer) => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('adm.customer.updated'),
              type: 'success',
            });
            this.$emit('update', customer);
            this.newBalance = 0;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code
              }`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.updating = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.preloader {
  position: absolute;
  top: 40px;
  right: 10px;

  &.pls-primary .plc-path {
    stroke: #635ebe;
  }
}
</style>
