<template>
  <InputComponent ref="input" type="tel" :label="label" :value="nationalNumber" :placeholder="mask" :mask="mask"
    @input="handleInput" :required="required">
    <template #prepend>
      <button type="button" class="btn dropdown-toggle" v-dropdown>
        <img :src="selectedCountry.flag" />
        {{ selectedCountry.code }}
      </button>
      <div class="dropdown-menu dropdown-menu-left dropdown-menu-accout">
        <a v-for="country in countries" :key="country.iso" class="dropdown-item" href="javascript:void(0)"
          @click="changeCountry(country)">
          <img :src="country.flag" />
          <strong>{{ country.name }}</strong>
          <span>({{ country.code }})</span>
        </a>
      </div>
    </template>
  </InputComponent>
</template>
<script>
import InputComponent from '@/components/form/InputComponent.vue';
import countries from '@/json/countries.json';

export default {
  name: 'TelInputComponent',
  components: {
    InputComponent,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countries,
      nationalNumber: '',
      timeout: 0,
      eventValue: '',
      tel: '',
      selectedCountry: null,
    };
  },
  watch: {
    // whenever question changes, this function will run
    value(newValue, oldValue) {
      this.detectCountry(newValue);
      this.nationalNumber = this.removeCountryCode(newValue);
    },
  },
  computed: {
    mask() {
      return this.selectedCountry.mask;
    },
    isValid() {
      return this.$refs.input.isValid;
    },
  },
  created() {
    this.selectedCountry = countries.filter(
      (country) => country.iso === 'BR',
    )[0];
  },
  methods: {
    handleInput(e) {
      const number = this.removeMask(e);
      this.detectCountry(number);
      this.$emit(
        'input',
        this.selectedCountry.code + this.removeCountryCode(number),
      );
    },
    changeCountry(country) {
      this.selectedCountry = country;
      this.$emit('input', this.selectedCountry.code + this.nationalNumber);
    },
    detectCountry(number) {
      if (number.includes('+')) {
        const country = countries.filter(
          (country) => number.substring(0, 2) === country.code ||
            number.substring(0, 3) === country.code ||
            number.substring(0, 4) === country.code,
        )[0];

        if (country) {
          this.selectedCountry = country;
        }
      }
    },
    removeCountryCode(number) {
      if (!number.includes('+')) {
        return number;
      }
      return number.replace(this.selectedCountry.code, '');
    },
    removeMask(value) {
      return value.replace(/\D/g, '');
    },
    checkValidation() {
      this.$refs.input.checkValidation();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dropdown-toggle {
  padding-left: 8px;
  padding-right: 25px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;

  img {
    max-width: 23px;
    margin-right: 5px;
  }
}

.dropdown-menu {
  width: auto;

  .dropdown-item {
    img {
      max-width: 23px;
      margin-right: 5px;
    }

    strong {
      margin-right: 5px;
    }
  }
}
</style>
