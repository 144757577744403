<template>
  <div class="content">
    <div class="export-loading" v-if="loading">
      <LoadingAnim />
    </div>
    <PageHeader :breadcrumbLinks="breadcrumbLinks" title="Whatsapp"
      :subtitle="'Deny List ' + $t('generic-str.and') + ' OPT-Outs'" />
    <section class="page-content container-fluid">
      <div class="row">
        <!-- Deny List -->
        <div class="denylist-col col-6 col-xl-6">
          <div class="card">
            <div class="card-header header_options">
              <div class="card-title header_title">
                <span class="material-symbols-outlined">person_off</span>
                <span>Deny List</span>
              </div>
              <form @submit.prevent="search" class="form-group col-6 m-auto mb-0">
                <div class="input-group">
                  <input placeholder="" type="text" class="form-control" :v-model="searchQuery" ref="searchInput" />
                  <div class="input-group-prepend">
                    <button @click="search" type="button" class="btn icon_btn m-0" style="width: 50px">
                      <span class="material-symbols-outlined"> search </span>
                    </button>
                  </div>
                </div>
              </form>
              <div class="opts_card">
                <!-- Criar novo -->
                <button class="btn btn-add-person" v-b-toggle.sidebar-right-blacklist>
                  <span class="material-icons add_person"> add </span>
                </button>
                <!-- Exportar -->
                <button-element type="" class="hide" @click="download" :disabled="blacklists.length == 0">
                  <span class="material-icons-outlined title span-donwloads">file_download</span>
                </button-element>
                <!-- Excluir -->
                <button class="btn" :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
                  @click="deleteAll" v-bind:disabled="selectedBlacklists.length == 0 || isSending">
                  <span class="material-symbols-outlined title span-donwloads">delete</span>
                </button>
              </div>
            </div>
            <div v-if="!blacklists.length && fetched" class="card-body" style="padding: 0">
              <LottieAnimNoData />
              <h5 class="card-title text-center">
                {{ $t("warnings.blacklist.none-deny") }}
              </h5>
              <!-- Nenhum deny list cadastrado -->
              <p class="text-center">{{ $t("warnings.blacklist.register-deny") }}</p>
              <!-- Cadastre seu primeiro deny list. -->
            </div>
            <div class="card-body" v-if="fetched" style="padding: 0">
              <div class="table-responsive" v-if="blacklists.length">
                <table class="table">
                  <thead>
                    <tr>
                      <!-- <th scope="col">
                        {{ $tc('app.name', 1) }}
                      </th> -->
                      <th class="checkbox-wrapper" scope="col">
                        <div class="control-group opt-2">
                          <label class="control control-checkbox" for="customCheckTAll"><input id="customCheckTAll"
                              v-model="selectAllBlacklists" type="checkbox" />
                            <div class="control_indicator"></div>
                          </label>
                        </div>
                        {{ $tc("generic-str.lbl-number", 1) }}
                      </th>
                      <th scope="col">
                        {{ $t("generic-str.expire") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(blacklist, i) in blacklists" :key="blacklist.id">
                      <!-- <td>
                        <span class="img-wrapper">
                          <div class="image">R</div>
                          <span>Rodrigo Silva</span>
                        </span>
                      </td> -->
                      <td data-label="Número: ">
                        <span class="checkbox-wrapper">
                          <div class="control-group opt-2">
                            <label class="control control-checkbox" :for="'blacklist-check' + i">
                              <input v-model="selectedBlacklists" :id="'blacklist-check' + i" :value="blacklist"
                                type="checkbox" />
                              <div class="control_indicator"></div>
                            </label>
                          </div>
                          <span>+{{ blacklist.phone_number }}</span>
                        </span>
                      </td>
                      <td v-if="blacklist.expiration_date" data-label="Expira em: ">
                        <span>
                          <span>{{ blacklist.expiration_date | formatDate24 }}</span>
                        </span>
                      </td>
                      <td v-else data-label="Expira em: ">
                        {{ $t("generic-str.never") }}
                      </td>
                      <td>
                        <DropdownComponent variant="left" left simple>
                          <template #button>
                            <span class="material-icons-outlined title">more_vert</span>
                          </template>
                          <ButtonComponent @click="remove(blacklist.id, blacklist.phone_number)">
                            {{ $t("generic-str.remove") }}
                          </ButtonComponent>
                        </DropdownComponent>
                        <!-- <div class="dropdown">
                          <a
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            ><span class="material-symbols-outlined title">
                              more_vert
                            </span></a
                          >
                          <div class="dropdown-menu" style="">
                            <a class="dropdown-item"> Edit </a
                            ><button class="dropdown-item">Remove</button>
                          </div>
                        </div> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else class="qt-block-ui relative" style="padding: 120px" />
            <div class="card-footer">
              <div class="container-fluid">
                <div class="row">
                  <Pagination :lastPage="pages" @change="fetch" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/Deny List -->
        <!-- OPT Outs -->
        <div class="col-lg-6">
          <opted-card />
        </div>
        <!--/OPT Outs -->
      </div>
      <!-- Keyword -->
    </section>
    <create-blacklist-modal id="create-blacklist-modal" @submit="fetch()" />
    <blacklist-modal id="blacklist-modal" @submit="fetch()" />
    <create-optouts-modal id="create-optouts-modal" @submit="fetchAll(1)" />
    <optouts-modal id="optouts-modal" @submit="fetchAll(1)" />
    <create-key-modal id="create-key-modal" @submit="fetchKeywords" />
    <key-modal id="key-modal" @submit="fetchKeywords" />
    <b-sidebar id="sidebar-right-blacklist" :title="'Novo'" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <p>Novo</p>
        <span v-b-toggle.sidebar-right-blacklist class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div>
        <form @submit.prevent="saveBlackList">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">Número</label>
                <div class="col-md-12">
                  <tel-input ref="phone_number" v-model="formBlackList.numbers" :value="formBlackList.numbers" />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label date-label-wrapper text-left col-md-12 text-left-m">
                  {{ $t("generic-str.expire") }}
                  <small>*Deixe o campo vazio para não expirar!</small>
                </label>
                <div class="col-md-12">
                  <y-date-picker :type="'input'" :options="optionCalendar" :format="'DD/MM/YYYY HH:mm'"
                    @change="changeSchedule" :allowType="false" mask="##/##/#### ##:##">
                  </y-date-picker>
                </div>
              </div>
              <div class="form-group row" style="margin-top: 60px">
                <div class="col-md-12">
                  <button :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }" :disabled="isSending"
                    type="submit" class="btn btn-primary" v-b-toggle.sidebar-right-blacklist>
                    {{ $t("generic-str.save") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-sidebar>
    <b-sidebar id="sidebar-right-blacklistedit" :title="'Editar'" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <p>Editar</p>
        <span v-b-toggle.sidebar-right-blacklistedit class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div>
        <form @submit.prevent="editBlackList">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">Número</label>
                <div class="col-md-12">
                  <input v-model="formBlackListEdit.phone_number" type="text" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">{{
                  $t("generic-str.expire")
                  }}</label>
                <div class="col-md-12">
                  <date-picker v-model="formBlackListEdit.expiration_date" :config="options"
                    class="form-control input-date" :placeholder="$t('generic-str.date-hour')"></date-picker>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <button :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }" :disabled="isSending"
                    type="submit" class="btn btn-primary" v-b-toggle.sidebar-right-blacklistedit>
                    {{ $t("generic-str.save") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Swal from 'sweetalert2';
import Pagination from '@/components/rebranding/Pagination.vue';
import PaginationComponent from '@/components/PaginationComponent.vue';
import ButtonElement from '@/components/rebranding/elements/ButtonElement.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
// Blacklist
import CreateBlacklistModal from '@/components/rebranding/whatsapp/CreateBlacklistModal.vue';
import BlacklistModal from '@/components/rebranding/whatsappj/BlacklistModal.vue';
import BlacklistService from '@/services/blacklist.service';
// Optout
import OptedCard from '@/components/rebranding/whatsappj/optouts/OptedCard.vue';
/* import KeywordsCard from '@/components/rebranding/whatsapp/optouts/KeywordsCard.vue'; */
import CreateKeyModal from '@/components/rebranding/whatsappj/CreateKeyModal.vue';
import KeyModal from '@/components/sms/KeyModal.vue';
import CreateOptoutsModal from '@/components/sms/CreateOptoutsModal.vue';
import OptoutsModal from '@/components/sms/OptoutsModal.vue';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import axios from '@/plugins/axios';
import SmsService from '@/services/sms.service';
import TelInput from '@/components/form/TelInput.vue';
import moment from 'moment';
import YDatePicker from '@/components/DatePicker/YDatePicker.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';

const webApi = axios();

export default {
  name: 'SmsDashboard',
  components: {
    YDatePicker,
    PageHeader,
    CreateBlacklistModal,
    BlacklistModal,
    Pagination,
    // PaginationComponent,
    ButtonElement,
    LottieAnimNoData,
    LoadingAnim,
    OptedCard,
    /* KeywordsCard, */
    CreateKeyModal,
    KeyModal,
    CreateOptoutsModal,
    OptoutsModal,
    datePicker,
    TelInput,
    DropdownComponent,
  },
  data() {
    return {
      optionCalendar: {
        timePicker: true,
        singleDatePicker: true,
        autoApply: true,
      },
      startDate: '',
      formBlackListEdit: {
        phone_number: '',
      },
      breadcrumbLinks: {
        1: '/whatsapp/reports/history',
        2: '/whatsapp/denylist',
      },
      fetched: false,
      isSending: false,
      searchQuery: null,
      blacklists: [],
      form: {
        page: 1,
        channel: 'whatsapp',
      },
      pages: 1,
      currentPage: 1,
      selectedBlacklists: [],
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        keepInvalid: true,
        icons: {
          time: 'fa fa-clock',
        },
      },
      formBlackList: {
        expired_at: '',
        numbers: '',
      },
      loading: false,
    };
  },
  mounted() {
    this.getAccountId();
  },
  computed: {
    selectAllBlacklists: {
      get() {
        if (this.templates) {
          return this.selectedBlacklists.length === this.templates.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.blacklists.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedBlacklists = selected;
      },
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    changeSchedule(date) {
      console.log('Data selecionada:', date);
      this.formBlackList.expired_at = date || null;
    },
    teste() {
      console.log('Teste data:');
    },
    editBlackList() {
      this.isSending = true;
      let expiredAt = '';
      console.log('Deny List');
      console.log(this.formBlackListEdit);
      if (this.formBlackListEdit.expiration_date) {
        const temp = moment(
          this.formBlackListEdit.expiration_date,
          'DD/MM/YYYY HH:mm',
        ).format('YYYY-MM-DD HH:mm');

        console.log('expiredAt2', temp);
        expiredAt = new Date(temp).toISOString();
        expiredAt = expiredAt.replace(/Z$/g, '').trim();
      }
      webApi
        .put(`/whatsapp/new/deny-list/${this.formBlackListEdit.id}`, {
          phone_number: this.formBlackListEdit.phone_number,
          expiration_date: expiredAt,
        })
        .then(
          (response) => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Deny list atualizada com sucesso',
              type: 'success',
            });
            this.isSending = false;
            this.fetch();
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
    },
    saveBlackList() {
      this.isSending = true;
      let expiredAt = null;

      console.log('Deny List');
      console.log(this.formBlackList);

      if (this.formBlackList.expired_at && this.formBlackList.expired_at.trim() !== '') {
        const temp = moment(this.formBlackList.expired_at, 'DD/MM/YYYY HH:mm').format(
          'YYYY-MM-DD HH:mm',
        );
        expiredAt = new Date(temp).toISOString();
        expiredAt = expiredAt.replace(/Z$/g, '').trim();
      }
      if (
        this.formBlackList.expired_at !== null &&
        this.formBlackList.expired_at.length > 0 &&
        this.formBlackList.expired_at.length < 16
      ) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: 'Data invalida',
          type: 'danger',
        });
        this.isSending = false;
      } else {
        webApi
          .post('/whatsapp/new/deny-list', {
            phone_number: this.formBlackList.numbers,
            expiration_date: expiredAt === '' ? null : expiredAt,
          })
          .then(
            (response) => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Deny list criada com sucesso',
                type: 'success',
              });
              this.fetch();
              this.formBlackList = {
                numbers: '',
                expired_at: '',
              };
              this.$forceUpdate();
              this.$refs.phone_number.clear();
            },
            (error) => {
              if (error.response.data.message.includes('409 Conflict')) {
                this.$toast.show({
                  title: 'Erro de validação',
                  content: 'O número já está cadastrado',
                  type: 'danger',
                });
              } else {
                this.$toast.show({
                  title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                  content: error.message,
                  type: 'danger',
                });
              }
            },
          )
          .finally(() => {
            this.isSending = false;
          });
      }
    },
    search() {
      this.fetched = false;
      const word = this.$refs.searchInput.value;
      if (word !== '') {
        webApi.get(`/whatsapp/new/deny-list/find/${word}`).then(
          (response) => {
            this.blacklists = response.data;
            this.fetched = true;
          },
          (error) => {
            this.$toast.show({
              title: 'Erro interno, por favor tente novamente mais tarde',
              content: error.message,
              type: 'danger',
            });
            this.isSending = false;
            console.log(error);
          },
        );
      } else {
        this.fetch();
      }
    },
    fetch(page = 1) {
      this.form.page = page;
      this.fetched = false;
      this.currentPage = page;

      webApi
        .get(`/whatsapp/new/deny-list?page=${this.currentPage}&size=${6}`)
        .then((response) => {
          this.blacklists = response.data.content;
          this.pages = response.data.totalPages;
          this.fetched = true;
        })
        .catch((error) => {
          this.$toast.show({
            title: 'Erro interno, por favor tente novamente mais tarde',
            content: error.message,
            type: 'danger',
          });
        });
    },
    selectedBlacklistsChange(param) {
      this.selectedBlacklists = param.selectedBlacklists;
    },
    remove(id, number) {
      Swal.fire({
        title: 'Remover deny list',
        text: `Tem certeza que gostaria de remover o número ${number}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          toDelete.push(id);
          this.isSending = true;
          webApi.delete(`/whatsapp/new/deny-list/${id}`).then(
            (response) => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Deny list criada com sucesso',
                type: 'success',
              });
              this.isSending = false;
              this.fetch();
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('warnings.blacklist.remove-deny'),
        text: this.$t('warnings.blacklist.text-deny'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          const mapRest = this.selectedBlacklists.map((item) => {
            toDelete.push(item.id);
            webApi.delete(`/whatsapp/new/deny-list/${item.id}`).then(
              (response) => { },
              (error) => {
                this.$toast.show({
                  title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                  content: 'Erro interno, por favor tente novamente mais tarde',
                  type: 'danger',
                });
              },
            );
            return item;
          });

          Promise.all(mapRest).then((rest) => {
            this.fetch();
          });
        }
      });
    },
    download() {
      const link = document.createElement('a');

      if (link.download === undefined) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: 'Sem suporte para download',
          type: 'danger',
        });

        return;
      }

      this.loading = true;

      BlacklistService.export({ channel: 'rcs' })
        .then(
          (data) => {
            const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) {
              // IE 10+
              navigator.msSaveBlob(blob, 'denylist.csv');
            } else {
              // feature detection
              // Browsers that support HTML5 download attribute
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', 'denylist.csv');
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          },
          (error) => {
            this.content = error;
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.loading = false;
        });
    },
    /* Optouts */
    fetchKeywords() {
      this.$refs.keywords.fetch(1);
    },
    selectedTemplatesChange(param) {
      this.selectedTemplates = param.selectedTemplates;
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  position: relative;
}

.export-loading {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.btn i {
  line-height: 1;
  color: inherit;
}

/* Rebranding */
.eye_icon {
  color: #bfc5cb;
}

.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }

  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }

  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }

  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}

.darkmode .rebranding .form-control,
.rebranding .form-control {
  border-right: unset !important;
}

.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}

.header_options .input-group>input,
.header_options .input-group button {
  height: 50px;
}

.rebranding .add_person {
  cursor: pointer;
}

.rebranding .opts_card button {
  height: fit-content;
  padding: 0;
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.img-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.img-wrapper>.image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #570069;
  width: 50px;
  height: 50px;
  padding: 24px;
  border-radius: 50%;
  color: #fff;
  font-weight: 400;
}

.table-infos td {
  vertical-align: unset;
}

.rebranding .card {
  height: calc(100% - 1.875rem);
}

.rebranding .purple-add {
  background-color: var(--clr-yup-purple);
  color: #fff !important;
  transition: 0.5s;
}

.rebranding .purple-add:hover {
  background-color: var(--clr-yup-purple-hover);
}

.list-options li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.list-options li .control-group {
  display: flex;
  gap: 10px;
  clear: both;
  width: 100%;
}

.list-options li .control-group .control {
  width: 100%;
}

.list-options li .control-group .control_indicator {
  float: left;
  margin-right: 10px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.table.table-infos th,
.table.table-infos thead th {
  border-bottom: unset;
}

/* Static pagination */
.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

/* @media (min-width: 1440px) {
  .denylist-col.col-lg-8 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
} */
@media (min-width: 992px) and (max-width: 1440px) {
  .denylist-col.col-xl-8 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.denylist-col .card-body {
  min-height: 300px;
}

.denylist-col .table-responsive {
  height: 100%;
}

.card {
  box-shadow: none;
}

.card-header {
  border-bottom: #6b23dc3d 4px solid !important;
  padding: 0px;
  padding-bottom: 5px;
}

.card-header .card-title {
  color: #697077 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
}

.header_title {
  display: flex;
  color: #697077 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.card-header input {
  height: 30px !important;
}

.card-header button {
  height: 30px !important;
}

.btn-add-person {
  height: 20px !important;
}

.btn-add-person .add_person {
  font-size: 20px !important;
  padding: 5px !important;
}

.span-donwloads {
  font-size: 22px !important;
  padding: 5px !important;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;

  &.busy {
    opacity: 0.55;
  }
}

.table th,
.table thead th {
  border: none;
}

td,
th {
  vertical-align: middle;
}

tr td {
  padding: 18px;
}

td {
  border: 1px solid #e5e5e5;
  border-style: solid none;
}

td:first-child {
  border-left-style: solid;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.text-primary {
  color: var(--clr-yup-purple) !important;
}

.link-out-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.link-message {
  margin-left: 3px;
  font-size: 18px;
}

.no-item-list {
  padding-top: 60px;
  width: 30%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #c7c7c7;
}

.no-item-list .material-symbols-outlined {
  font-size: 90px;
  margin-bottom: 10px;
}

::v-deep #sidebar-right-blacklist {
  background-color: var(--background-2) !important;
  width: 525px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

::v-deep #sidebar-right-blacklistedit {
  background-color: var(--background-2) !important;
  width: 525px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

::v-deep {

  // .bootstrap-datetimepicker-widget {
  // }
  .date-label-wrapper.control-label {
    display: flex;
    align-items: center;
    gap: 5px;

    small {
      position: relative;
      display: block;
      top: -2px !important;
    }
  }
}

@media screen and(max-width: 960px) {
  .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
