<template>
  <div :class="{ invalid: !isValid }" class="form-group">
    <label v-if="label">{{ label }}</label>
    <div class="input-group">
      <div v-if="$slots.prepend" class="input-group-prepend">
        <slot name="prepend"></slot>
      </div>
      <v-select :components="{ Deselect, OpenIndicator }" :disabled="disabled" :label="textField" :loading="loading"
        :multiple="multiple" :options="options" :reduce="option => option[valueField] ? option[valueField] : option"
        :value="value" :clearable="!required" @change="handleChange" @input="handleInput" />
      <div v-if="$slots.append" class="input-group-append">
        <slot name="append"></slot>
      </div>
    </div>
    <div v-if="!isValid" class="form-text text-danger">
      <template v-for="error in errors">
        {{ error }}
      </template>
    </div>
  </div>
</template>

<script>
import validationRules from '@/rules';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'SelectComponent',
  components: {
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    textField: {
      type: String,
      default: 'text',
    },
    valueField: {
      type: String,
      default: 'value',
    },
    value: {
      type: [String, Array, Object],
      default: '',
    },
    placeholder: {
      type: String,
    },
    variant: {
      type: String,
      default: 'primary',
      validator(value, props) {
        return ['primary', 'secondary', 'tertiary'].includes(value);
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      allRules: [],
      tokens: {
        '#': { pattern: /\d/ },
        X: { pattern: /[0-9a-zA-Z]/ },
        S: { pattern: /[a-zA-Z]/ },
        A: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
        a: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() },
        '!': { escape: true },
      },
      Deselect: {
        render: (createElement) => createElement('span', 'close'),
      },
      OpenIndicator: {
        render: (createElement) => createElement('span', 'expand_more'),
      },
    };
  },
  computed: {
    classObject() {
      return [
        this.variant,
      ];
    },
    isValid() {
      return this.errors.length === 0;
    },
  },
  created() {
    this.allRules = this.rules;
    if (this.required) {
      this.allRules.push('required');
    }
    if (this.type === 'email') {
      this.allRules.push('email');
    }
    if (this.type === 'tel') {
      this.allRules.push('tel');
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
    handleInput(value) {
      this.$emit('input', value);
      this.checkValidation();
    },
    handleChange(e) {
      this.checkValidation();
      this.$emit('change', e);
    },
    validate(value) {
      if (this.allRules.length) {
        this.errors = [];
        this.allRules.forEach((rule) => {
          rule = rule.split(':');
          if (validationRules[rule[0]]) {
            console.log(value);
            if (!validationRules[rule[0]].condition(value, rule[1])) {
              const message = validationRules[rule[0]].getMessage();

              if (message) {
                this.errors.push(this.$t(message, [rule[1]]));
              } else {
                this.errors.push('');
              }
            }
          }
        });
        // Validate mask
        if (this.mask && !this.errors.length) {
          if (value.length !== this.mask.length) {
            const message = validationRules.mask.getMessage();
            this.errors.push(this.$t(message));
          }
        }
      }
    },
    checkValidation() {
      this.$nextTick(() => {
        this.validate(this.value ?? '');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$border-width: 2px;

.form-group {
  margin-bottom: 1.62rem;

  &.invalid {
    .input-group {

      .form-control,
      .btn,
      .vs__dropdown-toggle {
        border-color: #ff5c75;
      }
    }
  }

  .form-control {
    height: 45px;
    padding: 12px 16px;
    border-width: $border-width;
    border-radius: 8px;
    border-color: #e5e5e5;
  }

  .form-text {
    font-size: 80%;
  }

  .input-group {
    .form-control {
      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }
    }

    .btn {
      border-color: var(--form-border);
      border-width: $border-width;
      border-style: solid;
      border-radius: 8px;
      height: 45px;

      &.btn-icon {
        font-size: 24px;
      }
    }

    .input-group-prepend {
      .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }
    }

    .input-group-append {
      .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
    }
  }
}

label {
  margin-bottom: 0.62rem;
}
</style>
<style lang="scss">
.form-group.invalid {
  .input-group .v-select .vs__dropdown-toggle {
    border-color: #ff5c75;
  }
}

.input-group {
  .v-select {
    width: 100%;

    &.vs--single {
      &.vs--open {
        .vs__selected {
          position: relative;
        }
      }
    }

    .vs__dropdown-toggle {
      display: flex;
      padding: 6.5px 10px;
      border-width: 2px;
      border-radius: 8px;
      border-color: #e5e5e5;

      .vs__selected-options {
        flex-grow: 1;

        .vs__search {
          font-size: 1em;
          border: none;
        }
      }

      .vs__actions {
        display: flex;
        padding: 4px 6px 0 3px;

        .vs__clear {
          display: flex;
        }

        span {
          font-size: 24px;
        }

        .vs__open-indicator {
          font-weight: normal;
          line-height: 1;
          color: #707070;
        }
      }

      .vs__selected {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
</style>
