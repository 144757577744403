import { render, staticRenderFns } from "./WhatsappBubble.vue?vue&type=template&id=51cd8c8b&scoped=true"
import script from "./WhatsappBubble.vue?vue&type=script&lang=js"
export * from "./WhatsappBubble.vue?vue&type=script&lang=js"
import style0 from "./WhatsappBubble.vue?vue&type=style&index=0&id=51cd8c8b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51cd8c8b",
  null
  
)

export default component.exports